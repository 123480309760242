import React, { useEffect, useState } from 'react';
import { connect} from 'react-redux';
import DatePicker from "react-datepicker";
import { useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { useParams } from 'react-router-dom'
import { InputGroup, Button } from 'react-bootstrap-v5';
 import { decimalValidate, getFormattedMessage, getFormattedOptions, placeholderText } from '../../shared/sharedMethod';
import ModelFooter from '../../shared/components/modelFooter';
import ReactSelect from '../../shared/select/reactSelect';
import {addProduct} from '../../store/action/productAction';
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import {editProductBatch} from '../../store/action/productBatchAction';


const BatchForm = (props) => {
    console.log("product batch props",props)
    const navigate = useNavigate();
    const {
        productBatchesData,
        editProductBatch,
        id,
        productBatch,
        frontSetting,
        baseUnits,
    } = props;

console.log('product batch in props',productBatch);
console.log('batch data in form',productBatchesData)
    const [productValue, setProductValue] = useState({});

    const [errors, setErrors] = useState({
        batch_no: '',
        stock: '',
        sales_price: '',
        purchase_price: '',
        expiry_date: '',
        manufacture_date: ''
    });

    const [batchValue, setBatchValue] = useState({
        date: new Date(),
        batch_no:'abc 123',
        stock: '4',
        sales_price: '100',
        purchase_price: '100',
        // expiry_date:'2023-09-30',
        // manufacture_date:'2023-09-27'
    });

    useEffect(() => {
        if (productBatch && productBatch.length > 0) {
            const productBatches = productBatch[0];
            setBatchValue({
                batch_no: 'abc 123',
                stock: '4',
                sales_price:  '100',
                purchase_price: '100',
                base_unit: '2',
                // expiry_date: '2023-09-30',
                // manufacture_date: '2023-09-27',
                // isEdit: productBatches ? productBatches[0].is_Edit : false
            });
        }
    }, [productBatch]);
console.log("values for product batch",productBatch)


    const handleProductUnitChange = (obj) => {
        productUnitDropdown(obj.value);
        setIsClearDropdown(false);
        setIsDropdown(false);
        setProductValue({ ...productValue, product_unit: obj });
        setErrors('');
    };

    const handleValidation = () => {
        let errors = {};
        let isValid = true;

        if (!batchValue['batch_no']) {
            errors['batch_no'] = getFormattedMessage('Please enter Batch No');
            isValid = false;
        }

        if (!batchValue['stock']) {
            errors['stock'] = getFormattedMessage('Please enter Stock');
            isValid = false;
        }

        if (!batchValue['sales_price']) {
            errors['sales_price'] = getFormattedMessage('Please enter Price');
            isValid = false;
        }

        if (!batchValue['purchase_price']) {
            errors['purchase_price'] = getFormattedMessage('Please enter Price');
            isValid = false;
        }

        if (!batchValue['unit']) {
            errors['unit'] = getFormattedMessage('Please choose Unit');
            isValid = false;
        }

        if (!batchValue['expiry_date']) {
            errors['expiry_date'] = getFormattedMessage('Please choose date');
            isValid = false;
        }

        if (!batchValue['manufacture_date']) {
            errors['manufacture_date'] = getFormattedMessage('Please choose date');
            isValid = false;
        }

        setErrors(errors);
        return isValid;
    };


    // const onChangeInput = (e) => {
    //     console.log("target",e.target)
    //     e.preventDefault();
    //     const { value } = e.target;
    //     // check if value includes a decimal point
    //     if (value.match(/\./g)) {
    //         const [, decimal] = value.split('.');
    //         // restrict value to only 2 decimal places
    //         if (decimal?.length > 2) {
    //             // do nothing
    //             return;
    //         }
    //     }
    //     if (e.target.name == productValue.product_category_id) {
    //     setProductValue(inputs => ({ ...inputs, [e.target.name]: null }))
    //     }


    //     setProductValue(inputs => ({ ...inputs, [e.target.name]: value }))
    //     setErrors('');
    // };
    const [isClearDropdown, setIsClearDropdown] = useState(true);
    const [isDropdown, setIsDropdown] = useState(true);

    const prepareFormData = (data) => {
        const formData = new FormData();
        formData.append('batch_no', data.batch_no);
        formData.append('sales_price', data.sales_price);
        formData.append('purchase_price', data.purchase_price);
        formData.append('stock', data.stock);
        formData.append('base_unit', data.base_unit);
        formData.append('expiry_date)', moment(data.expiry_date).format('YYYY-MM-DD'));
        formData.append('manufacture_date', moment(data.manufacture_date).format('YYYY-MM-DD'));
        return formData;
    };


    const handleBatchNoChange = (event) => {
        const { value } = event.target;
        setBatchValue({ ...batchValue, batch_no: value });
        setErrors({ ...errors, batch_no: '' });
      };

      const handleStockChange = (event) => {
        const { value } = event.target;
        setBatchValue({ ...batchValue, stock: value });
        setErrors({ ...errors, stock: '' });
      };

      const handleAsOfDateChange = (date) => {
        setBatchValue({ ...batchValue, currentDate: date });
        setErrors({ ...errors, asOfDate: '' });
      };


      const handleSalesPriceChange = (event) => {
        const { value } = event.target;
        setBatchValue({ ...batchValue, sales_price: value });
        setErrors({ ...errors, sales_price: '' });
      };

      const handlePurchasePriceChange = (event) => {
        const { value } = event.target;
        setBatchValue({ ...batchValue, purchase_price: value });
        setErrors({ ...errors, purchase_price: '' });
      };


    const handleManufactureDateChange = (date) => {
        setBatchValue({ ...batchValue, manufacture_date: date });
        setErrors({ ...errors, manufacture_date: '' });
  };

    const handleExpiryDateChange = (date) => {
        setBatchValue({ ...batchValue, expiry_date: date });
        setErrors({ ...errors, expiry_date: '' });
  };

  const handleUnitChange = (event) => {
    const { value } = event.target;
    setBatchValue({ ...batchValue, unit: value });
    setErrors({ ...errors, unit: '' });
  };


    const onSubmit = (event) => {
         console.log("event worked")

        event.preventDefault();
        // const batchValueWithProductId = {
        //     ...batchValue,
        //     product_id: id,
        // };
        console.log('batch values', batchValue)

        const valid = handleValidation();
        console.log('valid', valid)
        if(id) {
            console.log("edit product id cond",id);
            editProductBatch(productBatchesData[0].id, batchValue, navigate);
        }
        if (productBatchesData && isClearDropdown === false  && isDropdown === true) {
            if (!disabled) {
                console.log('edit product working')
                editProductBatch(id, prepareFormData(batchValue), navigate);
            }
        } else {
            if (!valid) {
                console.log('add product proccessing started')

                setBatchValue(batchValue);
                addProduct(prepareFormData(batchValue));
            }
        }
    };
    const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    event.preventDefault();
    setIsOpen(!isOpen);
  };
  const [date, setDate] = useState(new Date());

//   const handleDateChange = (selectedDate) => {
//     setDate(selectedDate);
//   };
return (
  <div className='card'>
    <div className='card-body'>
     <Form>
     <div className='row'>
         <div className=''>
            <div className='card'>
                <div className='col-xl-12 mb-3'>
                     <div className='row'>

                     <div className='col-xl-12 mb-3'>
    <div className='row'>
        <div className='col-md-12 mb-3'>
            <h1>{getFormattedMessage('Batches')}:</h1>
        </div>
        <div className='col-xl-4 mb-3'>
            <div className='row'>
            <div className='col-md-12 mb-3'>
                <label className='form-label'>{getFormattedMessage('Batch_No')}:</label>
                <input
                    type='text'
                    name='batch_no'
                    className='form-control'
                    value={batchValue.batch_no}
                    onChange={handleBatchNoChange}
                    placeholder={placeholderText('Enter Batch.No')}
                />
                </div>
            </div>
        </div>
        <div className='col-xl-4 mb-3'>
            <div className='row'>
                <div className='col-md-12 mb-3'>
                    <label className='form-label'>{getFormattedMessage('Stock')}:</label>
                   <input
                    type='number'
                    name='stock'
                    className='form-control'
                    placeholder='Enter stock'
                    min={0}
                    value={batchValue.stock}
                    onChange={handleStockChange}
                    />
        {errors['stock'] && <span className='error'>{errors['stock']}</span>}
                </div>
            </div>
        </div>
        <div className='col-xl-4 mb-3'>
            <div className='row'>
                <div className='col-md-12 mb-3'>
                <label className='form-label'>{getFormattedMessage('As of Date')}:</label>
                <br/>
               <DatePicker
                    selected={date}
                    value={batchValue.date}
                    className='form-control'
                    onChange={handleAsOfDateChange}
                    placeholder={placeholderText('product.input.stock-alert.placeholder.label')}
                  />
                </div>
            </div>
        </div>
        <div className='col-xl-4 mb-3'>
            <div className='row'>
                <div className='col-md-12 mb-3'>
                    <label className='form-label'>{getFormattedMessage('Sales Price')}:</label>
                    <InputGroup>
                    <input
                        type=''
                        name='sales_price'
                        className='form-control'
                        value={batchValue.sales_price}
                        onChange={handleSalesPriceChange}
                        placeholder={placeholderText('Enter Price')}
                        />
                        <InputGroup.Text>{frontSetting.value && frontSetting.value.currency_symbol}</InputGroup.Text>
                        </InputGroup>

              {errors['sales_price'] && <span className='error'>{errors['sales_price']}</span>}
                </div>
            </div>
        </div>
        <div className='col-xl-4 mb-3'>
            <div className='row'>
                <div className='col-md-12 mb-3'>
                <InputGroup className='flex-nowrap dropdown-side-btn'>
                         <ReactSelect
                           className='position-relative'
                            title={getFormattedMessage("Unit")}
                           placeholder={placeholderText('Choose Unit')}
                           defaultValue={productValue.product_unit}
                           value={productValue.product_unit}
                           data={baseUnits}
                           errors={errors['product_unit']}
                           onChange={handleProductUnitChange}
                           required={true} />
                          <Button onClick={() => showUnitModel(true)} className='position-absolute model-dtn'><FontAwesomeIcon
                        icon={faPlus} /></Button></InputGroup>
                </div>
            </div>
        </div>
        <div className='col-xl-4 mb-3'>
            <div className='row'>
                <div className='col-md-12 mb-3'>
                    <label className='form-label'>{getFormattedMessage('Purchase Price')}:</label>
                    <InputGroup>
                    <input
                        type=''
                        name='purchase_price'
                        className='form-control'
                        value={batchValue.purchase_price}
                        onChange={handlePurchasePriceChange}
                        placeholder={placeholderText('Enter Price')}
                    />
                    <InputGroup.Text>{frontSetting.value && frontSetting.value.currency_symbol}</InputGroup.Text>
                    </InputGroup>
                      {errors['purchase_price'] && <span className='error'>{errors['purchase_price']}</span>}
                </div>
                </div>
                </div>

                <div className='col-md-4 mb-3'>
                    <label className='form-label'>{getFormattedMessage('Exp.Date')}:</label>
                            <br/>
                <DatePicker
                    selected={batchValue.expiry_date}
                    onChange={handleExpiryDateChange}
                    className='form-control'
                    placeholderText='Select Date'
                />
                        {errors['expiry_date'] && <span className='error'>{errors['expiry_date']}</span>}
                </div>
                <div className='col-xl-4 mb-3'>
            <div className='row'>
                <div className='col-md-12 mb-3'>
                <label className='form-label'>{getFormattedMessage('Mfg.Date')}:</label>
                <br/>
                <DatePicker
                selected={batchValue.manufacture_date}
                onChange={handleManufactureDateChange}
                className='form-control'
                placeholderText='Select Date'
          />

                        {errors['manufacture_date'] && <span className='error'>{errors['manufacture_date']}</span>}
                </div>
            </div>
            </div>
    </div>
</div>

                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    <ModelFooter onEditRecord={productBatch} onSubmit={(e) => { onSubmit(e) }}/>
                </Form>
            </div>
            {/* {unitModel && <UnitsForm
                title={getFormattedMessage('unit.create.title')} show={unitModel}
                hide={setUnitModel} />} */}
        </div>
    )

}
    ;

const mapStateToProps = (state) => {
    const { productBatchesData,products,frontSetting} = state;
    console.log("stating products",productBatchesData)
    return { products,productBatchesData,frontSetting};
};

export default connect(mapStateToProps, {
    addProduct,
    editProductBatch,


})(BatchForm);
