import React, { useEffect, useState } from 'react';
import { connect} from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import Form from 'react-bootstrap/Form';
import { useParams } from 'react-router-dom'
import Select from 'react-select';
import { InputGroup, Button } from 'react-bootstrap-v5';
import MultipleImage from './MultipleImage';
import { fetchUnits } from '../../store/action/unitsAction';
import { fetchAllProductCategories } from '../../store/action/productCategoryAction';
import { fetchAllBrands } from '../../store/action/brandsAction';
import { editProduct,fetchProduct,addProduct} from '../../store/action/productAction';
import { productUnitDropdown } from '../../store/action/productUnitAction';
import { decimalValidate, getFormattedMessage, getFormattedOptions, placeholderText } from '../../shared/sharedMethod';
import taxes from '../../shared/option-lists/taxType.json';
import barcodes from '../../shared/option-lists/barcode.json';
import ModelFooter from '../../shared/components/modelFooter';
import ReactSelect from '../../shared/select/reactSelect';
import { saleStatusOptions, taxMethodOptions, productTypeOptions } from '../../constants';
import { fetchAllWarehouses } from "../../store/action/warehouseAction";
import { fetchAllSuppliers } from "../../store/action/supplierAction";
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import BrandsFrom from '../brands/BrandsFrom';
import { addBrand } from '../../store/action/brandsAction';
import UnitsForm from '../units/UnitsForm';
import { addUnit } from '../../store/action/unitsAction';

const ProductForm = (props) => {
    const{products}=props
    console.log("products ",products)
    const { id } = useParams();
    useEffect(()=>{
      if(id){
        console.log("id for product",products.id)
        fetchProduct(id);
        //fetchProductBatches(id);
        }

    },[id])
    console.log("id:",id)
    const {
        addProduct,
        warehouses,
        suppliers,
        editProduct,
        singleProduct,
        brands,
        fetchAllBrands,
        fetchAllProductCategories,
        productCategories,
        fetchUnits,
        productUnits,
        productUnitDropdown,
        frontSetting,
        fetchAllWarehouses,
        fetchAllSuppliers,
        addBrand,
        addUnit,
        baseUnits,
        productUnit
    } = props;
    console.log("product form props:", props)
    console.log("product batch setting",frontSetting)
    console.log('single product',singleProduct)

    // const item = products.length === 1 && products.map(product => ({
    //     id: product.id,

    // }));

    const [showColumns, setShowColumns] = useState(false);

    const toggleColumns = () => {
        setShowColumns(!showColumns);
      };

    const navigate = useNavigate();
    const [productValue, setProductValue] = useState({
        date: new Date(),
        name: '',
        code: '',
        sku: '',
        product_category_id: '',
        brand_id: '',
        barcode_symbol: '',
        product_cost: '',
        product_price: '',
        product_unit: '',
        sale_unit: '',
        purchase_unit: '',
        stock_alert: 0,
        order_tax: 0,
        tax_type: '',
        sale_quantity_limit: "",
        notes: '',
        images: [],
        warehouse_id: '',
        supplier_id: '',
        add_stock: '',
        status_id: { label: getFormattedMessage("status.filter.received.label"), value: 1 },
        product_type: '',
        MRP: '',
        HSN_code: '',
        base_unit:'',
        isEdit: false
    });

    const[batchValue,setBatchValue]= useState({
        date: new Date(),
        batch_no:'',
        stock:'',
        sales_price:'',
        purchase_price:'',
        expiry_date:'',
        manufacture_date:''
    });

    const [removedImage, setRemovedImage] = useState([])
    const [isClearDropdown, setIsClearDropdown] = useState(true);
    const [isDropdown, setIsDropdown] = useState(true);
    const [multipleFiles, setMultipleFiles] = useState([]);
    const [errors, setErrors] = useState({
        name: '',
        code: '',
        sku: '',
        product_cost: '',
        product_price: '',
        product_unit: '',
        images: [],
        warehouse_id: '',
        supplier_id: '',
        add_stock: '',
        status_id: '',
        product_type: '',
        MRP: '',
        HSN_code: '',
        batch_no:'',
        stock:'',
        sales_price:'',
        base_unit:'',
        purchase_price:''

    });
    console.log("Errors", errors)



    useEffect(() => {
        fetchAllBrands();
        fetchAllProductCategories();
        fetchUnits();
        fetchAllWarehouses();
        fetchAllSuppliers();
    }, []);

    useEffect(() => {
        if (singleProduct && productUnit) {
            productUnitDropdown(productUnit[0]?.id)
        }
    }, [])

    const newTax = singleProduct && taxes.filter((tax) => singleProduct[0].tax_type === tax.value);
    // const newType = singleProduct && types.filter((type) => singleProduct[0].type === type.value);
    const newBarcode = singleProduct && barcodes.filter((barcode) =>  singleProduct[0].barcode_symbol.toString() === barcode.value);
    const disabled = multipleFiles.length !== 0 ? false : singleProduct && productValue.product_unit && productValue.product_unit === singleProduct[0].product_unit && productValue.barcode_symbol[0] && productValue.barcode_symbol === singleProduct[0].barcode_symbol.toString() && singleProduct[0].name === productValue.name && singleProduct[0].notes === productValue.notes && singleProduct[0].product_price === productValue.product_price && singleProduct[0]?.stock_alert?.toString() === productValue.stock_alert && singleProduct[0].product_cost === productValue.product_cost && singleProduct[0].code === productValue.code && JSON.stringify(singleProduct[0].order_tax) === productValue.order_tax && singleProduct[0].quantity_limit === productValue.sale_quantity_limit && singleProduct[0].brand_id.value === productValue.brand_id.value && newTax.length === productValue.tax_type.length && singleProduct[0].product_category_id.value === productValue.product_category_id.value && JSON.stringify(singleProduct[0].images.imageUrls) === JSON.stringify(removedImage)
    const [selectedBrand] = useState(singleProduct && singleProduct[0] ? ([{
        label: singleProduct[0].brand_id.label, value: singleProduct[0].brand_id.value
    }]) : null);

    const [selectedBarcode] = useState(newBarcode && newBarcode[0] ? ([{
        label: newBarcode[0].label,
        value: newBarcode[0].value
    }]) : null);

    const [selectedProductCategory] = useState(singleProduct && singleProduct[0] ? ([{
        label: singleProduct[0].product_category_id.label, value: singleProduct[0].product_category_id.value
    }]) : null);

    const [selectedTax] = useState(newTax && newTax[0] ? ([{ label: newTax[0].label, value: newTax[0].value }]) : null);

    const saleUnitOption = productUnits && productUnits.length && productUnits.map((productUnit) => {
        return { value: productUnit?.id, label: productUnit.attributes.name }
    });

    useEffect(() => {
        console.log("useeffct calling")
        if (singleProduct) {
            const product = singleProduct[0];
            console.log("values for product",product)
            setProductValue({
                name: product.name || '',
                code: product.code || '',
                product_category_id:product.product_category_id || '' ,
                brand_id: product. brand_id|| '',
                barcode_symbol:product.selectedBarcode,
                product_cost: product.product_cost || '',
                product_price: product.product_price || null,
                product_unit: productUnit[0] ? { value: productUnit[0].id, label: productUnit[0].attributes.name } : null,
                sale_unit: product.sale_unit || '',
                purchase_unit: product.purchase_unit || '',
                stock_alert: product.stock_alert || 0,
                order_tax: product.order_tax ? JSON.stringify(product.order_tax) : 0,
                tax_type: newTax,
                sale_quantity_limit: product.quantity_limit || '',
                notes: product.notes || '',
                images: product.images || '',
                // product_type: newType,
                MRP: product.MRP || '',
                isEdit: singleProduct ? singleProduct[0].is_Edit : false
            })
        }
    }, []);


    const onChangeFiles = (file) => {
        setMultipleFiles(file);
    };

    const transferImage = (item) => {
        setRemovedImage(item);
        setMultipleFiles([])
    };


    const handleProductUnitChange = (obj) => {
        productUnitDropdown(obj.value);
        setIsClearDropdown(false);
        setIsDropdown(false);
        setProductValue({ ...productValue, product_unit: obj });
        setErrors('');
    };
    console.log("Error",)
    const handleSaleUnitChange = (obj) => {
        setIsClearDropdown(true);
        setProductValue({ ...productValue, sale_unit: obj });
        setErrors('');
    };

    const handlePurchaseUnitChange = (obj) => {
        setIsDropdown(true);
        setProductValue({ ...productValue, purchase_unit: obj });
        setErrors('');
    };

    const onBrandChange = (obj) => {
        setProductValue(productValue => ({ ...productValue, brand_id: obj }));
        setErrors('');
    };

    const onBarcodeChange = (obj) => {
        setProductValue(productValue => ({ ...productValue, barcode_symbol: obj }));
        setErrors('');
    };

    const onProductCategoryChange = (obj) => {
        setProductValue(productValue => ({ ...productValue, product_category_id: obj }));
        setErrors('');
    };

    const productTypeFilterOptions = getFormattedOptions(productTypeOptions)


    const onProductTypeChange = (obj) => {
        setProductValue(productValue => ({ ...productValue, product_type: obj }));
        setErrors('');
    };
    // tax type dropdown functionality
    const taxTypeFilterOptions = getFormattedOptions(taxMethodOptions)
    const [taxType, setTaxType] = useState(singleProduct ? singleProduct[0].tax_type === '1' ? {
        value: 1, label: getFormattedMessage("tax-type.filter.exclusive.label")
    } : {
        value: 2, label: getFormattedMessage("tax-type.filter.inclusive.label")
    } || singleProduct[0].tax_type === '2' ? {
        value: 2, label: getFormattedMessage("tax-type.filter.inclusive.label")
    } : {
        value: 1, label: getFormattedMessage("tax-type.filter.exclusive.label")
    } : '');

    const defaultTaxType = singleProduct ? singleProduct[0].tax_type === '1' ? {
        value: 1, label: getFormattedMessage("tax-type.filter.exclusive.label")
    } : {
        value: 2, label: getFormattedMessage("tax-type.filter.inclusive.label")
    } || singleProduct[0].tax_type === '2' ? {
        value: 2, label: getFormattedMessage("tax-type.filter.inclusive.label")
    } : {
        value: 1, label: getFormattedMessage("tax-type.filter.exclusive.label")
    } : {
        value: 1, label: getFormattedMessage("tax-type.filter.exclusive.label")
    }

    const onTaxTypeChange = (obj) => {
        setProductValue(productValue => ({ ...productValue, tax_type: obj }));
        setErrors('');
    };

    const onWarehouseChange = (obj) => {
        setProductValue(inputs => ({ ...inputs, warehouse_id: obj }))
        setErrors('')
    };

    const onSupplierChange = (obj) => {
        setProductValue(inputs => ({ ...inputs, supplier_id: obj }))
        setErrors('');
    };

    const onStatusChange = (obj) => {
        setProductValue(inputs => ({ ...inputs, status_id: obj }))
    };


    const statusFilterOptions = getFormattedOptions(saleStatusOptions)
    const statusDefaultValue = statusFilterOptions.map((option) => {
        return {
            value: option.id,
            label: option.name
        }
    })

    const handleValidation = () => {
        let errorss = {};
        let isValid = false;
        if (!productValue['name']) {
            errorss['name'] = getFormattedMessage('globally.input.name.validate.label');
        } else if (!productValue['code']) {
            errorss['code'] = getFormattedMessage('product.input.code.validate.label');
        // } else if (!productValue['product_category_id']) {
        //     errorss['product_category_id'] = getFormattedMessage('product.input.product-category.validate.label');
        // } else if (!productValue['brand_id']) {
        //     errorss['brand_id'] = getFormattedMessage('product.input.brand.validate.label');
        // } else if (!productValue['barcode_symbol']) {
        //     errorss['barcode_symbol'] = getFormattedMessage('product.input.barcode-symbology.validate.label');
        } else if (!productValue['product_cost']) {
            errorss['product_cost'] = getFormattedMessage('product.input.product-cost.validate.label');
        // } else if (!productValue['product_price']) {
        //     errorss['product_price'] = getFormattedMessage('product.input.product-price.validate.label');
        // } else if (!productValue['product_unit']) {
        //     errorss['product_unit'] = getFormattedMessage('product.input.product-unit.validate.label');
        // } else if (!productValue['sale_unit']) {
        //     errorss['sale_unit'] = getFormattedMessage('product.input.sale-unit.validate.label');
        // } else if (isClearDropdown === false) {
        //     errorss['sale_unit'] = getFormattedMessage('product.input.sale-unit.validate.label');
        // } else if (!productValue['purchase_unit']) {
        //     errorss['purchase_unit'] = getFormattedMessage('product.input.purchase-unit.validate.label');
        // } else if (isDropdown === false) {
        //     errorss['purchase_unit'] = getFormattedMessage('product.input.purchase-unit.validate.label');
        // } else if (productValue['order_tax'] > 100) {
        //     errorss['order_tax'] = getFormattedMessage('product.input.order-tax.valid.validate.label');
        } else if (productValue['notes'] && productValue['notes'].length > 100) {
            errorss['notes'] = getFormattedMessage('globally.input.notes.validate.label');
        // } else if (productValue['MRP']) {
        //     errorss['MRP'] = getFormattedMessage('Please Enter Price');
        } else if (productValue['HSN_code']) {
            errorss['HSN_code'] = getFormattedMessage('Please Enter Code');

        } else if (productValue['isEdit'] === false) {
            if (!productValue['warehouse_id']) {
                errorss['warehouse_id'] = getFormattedMessage('purchase.select.warehouse.validate.label');
            } else if (!productValue['supplier_id']) {
                errorss['supplier_id'] = getFormattedMessage('purchase.select.supplier.validate.label');
            } else if (!productValue['add_stock']) {
                errorss['add_stock'] = getFormattedMessage('purchase.product.quantity.validate.label');
            } else if (!productValue['status_id']) {
                errorss['status_id'] = getFormattedMessage('globally.status.validate.label')
            } else {
                isValid = true;
            }
        } else {
            isValid = true;
        }
        setErrors(errorss);
        return isValid;
    };


    const onChangeInput = (e) => {
        console.log("target",e.target)
        e.preventDefault();
        const { value } = e.target;
        // check if value includes a decimal point
        if (value.match(/\./g)) {
            const [, decimal] = value.split('.');
            // restrict value to only 2 decimal places
            if (decimal?.length > 2) {
                // do nothing
                return;
            }
        }
        if (e.target.name == productValue.product_category_id) {
        setProductValue(inputs => ({ ...inputs, [e.target.name]: null }))
        }


        setProductValue(inputs => ({ ...inputs, [e.target.name]: value }))
        setErrors('');
    };

    // let manage_purchase_stock = [];
    // manage_purchase_stock.push(productValue?.add_stock, productValue?.supplier_id?.value, productValue?.warehouse_id?.value, productValue?.status_id?.value)

    const [creatBrandModel, setCreatBrandModel] = useState(false);
    const customerModel = (val) => {
        setCreatBrandModel(val)
    }

    const addBrandData = (formValue) => {
        addBrand(formValue, Filters.OBJ)
    };


    const [unitModel, setUnitModel] = useState(false);
    const showUnitModel = (val) => {
        setUnitModel(val)
    }

    const addUnitsData = (productValue) => {
        addUnit(productValue);
    };

    const prepareFormData = (data) => {
        console.log("datas passed",data)
        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('code', data.code);
        formData.append('product_category_id', data.product_category_id?data.product_category_id.value:0 );
        formData.append('brand_id', data.brand_id?data.brand_id.value:0);
         formData.append('barcode_symbol', 'default_value');
        formData.append('product_cost', data.product_cost);
        formData.append('product_price', data.product_price ? data.product_price:0);
        formData.append('product_unit', data.product_unit && data.product_unit ? data.product_unit.value : 0);
        formData.append('sale_unit', data.sale_unit && data.sale_unit ? data.sale_unit.value : 0);
        formData.append('purchase_unit', data.purchase_unit && data.purchase_unit ? data.purchase_unit.value : 0);
        formData.append('stock_alert', data.stock_alert ? data.stock_alert : 0); 
        formData.append('order_tax', data.order_tax ? data.order_tax : 0);
        formData.append('quantity_limit', data.sale_quantity_limit ? data.sale_quantity_limit : 0);
  
            formData.append('tax_type', data.tax_type.value ? data.tax_type.value : 0);
        
              formData.append('notes', data.notes ? data.notes : null);
    
                formData.append('product_type', data.product_type.value ? data.product_type.value : 1);
        
        

        formData.append('MRP', data.MRP ? data.MRP : 0);
        formData.append('HSN_code', data.HSN_code);
        
            formData.append('purchase_supplier_id', data.supplier_id.value ? data.supplier_id.value : null);
            formData.append('purchase_warehouse_id', data.warehouse_id.value ? data.warehouse_id.value : null);
            formData.append('purchase_date', moment(data.date).format('YYYY-MM-DD'));
            formData.append('purchase_quantity', data.add_stock ? data.add_stock : 0);
            formData.append('purchase_status', data.status_id.value) ? data.status_id.value : 0;
        
        if (multipleFiles) {
            multipleFiles.forEach((image, index) => {
                formData.append(`images[${index}]`, image);
            })
        }
        // formData.append('batch_no', data.batch_no);
        // formData.append('sales_price', data.sales_price);
        // formData.append('purchase_price', data.purchase_price);
        // formData.append('stock', data.stock);
        // formData.append('base_unit', data.base_unit);
        // formData.append('expiry_date)', moment(data.expiry_date).format('YYYY-MM-DD'));
        // formData.append('manufacture_date', moment(data.manufacture_date).format('YYYY-MM-DD'));
        return formData;
    };


    const handleBatchNoChange = (event) => {
        const { value } = event.target;
        setBatchValue({ ...batchValue, batch_no: value });
        setErrors({ ...errors, batch_no: '' });
      };

      const handleStockChange = (event) => {
        const { value } = event.target;
        setBatchValue({ ...batchValue, stock: value });
        setErrors({ ...errors, stock: '' });
      };

      const handleAsOfDateChange = (date) => {
        setBatchValue({ ...batchValue, currentDate: date });
        setErrors({ ...errors, asOfDate: '' });
      };


      const handleSalesPriceChange = (event) => {
        const { value } = event.target;
        setBatchValue({ ...batchValue, sales_price: value });
        setErrors({ ...errors, sales_price: '' });
      };

      const handlePurchasePriceChange = (event) => {
        const { value } = event.target;
        setBatchValue({ ...batchValue, purchase_price: value });
        setErrors({ ...errors, purchase_price: '' });
      };


    const handleManufactureDateChange = (date) => {
        setBatchValue({ ...batchValue, manufacture_date: date });
        setErrors({ ...errors, manufacture_date: '' });
  };

    const handleExpiryDateChange = (date) => {
        setBatchValue({ ...batchValue, expiry_date: date });
        setErrors({ ...errors, expiry_date: '' });
  };

  const handleUnitChange = (event) => {
    const { value } = event.target;
    setBatchValue({ ...batchValue, unit: value });
    setErrors({ ...errors, unit: '' });
  };


    const onSubmit = (event) => {
        // console.log("event worked")
        //
        event.preventDefault();
        const batchValueWithProductId = {
            ...batchValue,
            product_id: id,
        };
        console.log('batch values', batchValue)

        const valid = handleValidation();
        console.log('valid', valid)
        productValue.images = multipleFiles;
        if (singleProduct && valid && isClearDropdown === true && isDropdown === true) {
            if (!disabled) {
                console.log('edit product working')
                editProduct(id, prepareFormData(productValue), navigate);
            }
        } else {
            if (!valid) {
                console.log('add product proccessing started')

                productValue.images = multipleFiles;
                setProductValue(productValue);
                console.log("batch detail",batchValue)
                addProduct(prepareFormData(productValue),batchValue,navigate);
            }
        }
    };
const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    event.preventDefault();
    setIsOpen(!isOpen);
  };
  useEffect(()=>{
if(singleProduct){
    setIsOpen(true)
 }
  },[singleProduct])
  const [date, setDate] = useState(new Date());

  const handleDateChange = (selectedDate) => {
    setDate(selectedDate);
  };
    return (
        <div className='card'>
            <div className='card-body'>
                <Form>
                    <div className='row'>
                        <div className=''>
                            <div className='card'>
                                <div className='col-xl-12 mb-3'>
                                    <div className='row'>
                                        <div className='col-md-12 mb-3'>
                                            <h1>{getFormattedMessage('General Details')}:</h1>
                                        </div>
                                        <div className='col-xl-8 mb-3'>
                                            <div className='row' >
                                                <div className='col-md-6 mb-3'>
                                                    <label
                                                        className='form-label'>{getFormattedMessage('globally.input.name.label')}: </label>
                                                    <span className='required' />
                                                    <input type='text' name='name' value={productValue.name}
                                                        placeholder={placeholderText('globally.input.name.placeholder.label')}
                                                        className='form-control' autoFocus={true}
                                                        onChange={(e) => onChangeInput(e)} />
                                                    <span
                                                        className='text-danger d-block fw-400 fs-small mt-2'>{errors['name'] ? errors['name'] : null}</span>
                                                </div>
                                                <div className='col-md-6 mb-3' >
                                                    <label className="form-label">
                                                        Product Type:<span style={{ color: '#F62947' }}>*</span>
                                                    </label>
                                                    <ReactSelect
                                                        multiLanguageOption={productTypeFilterOptions}
                                                        onChange={onProductTypeChange}
                                                        errors={errors['product_type']}
                                                        placeholder={placeholderText("choose Product Type")}
                                                        required={true}
                                                    />
                                                </div>
                                                <div className='col-md-6 mb-3'>
                                                    <label
                                                        className='form-label'>{getFormattedMessage('product.input.code.label')}: </label>
                                                    <span className='required' />
                                                    <input type='text' name='code' className=' form-control'
                                                        placeholder={placeholderText('product.input.code.placeholder.label')}
                                                        onChange={(e) => onChangeInput(e)}
                                                        value={productValue.code} />
                                                    <span
                                                        className='text-danger d-block fw-400 fs-small mt-2'>{errors['code'] ? errors['code'] : null}</span>
                                                </div>
                                                <div className='col-md-6 mb-3'>
                                                    <label
                                                        className='form-label'>{getFormattedMessage('product.input.product-cost.label')}: </label>
                                                    <span className='required' />
                                                    <InputGroup>
                                                        <input type='text' name='product_cost'
                                                            min={0} className='form-control'
                                                            placeholder={placeholderText('product.input.product-cost.placeholder.label')}
                                                            onKeyPress={(event) => decimalValidate(event)}
                                                            onChange={(e) => onChangeInput(e)}
                                                            value={productValue.product_cost} />
                                                        <InputGroup.Text>{frontSetting.value && frontSetting.value.currency_symbol}</InputGroup.Text>
                                                    </InputGroup>
                                                    <span
                                                        className='text-danger d-block fw-400 fs-small mt-2'>{errors['product_cost'] ? errors['product_cost'] : null}</span>
                                                </div>

                                                <div className='col-md-6 mb-3' style={{ marginBottom: "100px" }}>
                                                    <label className='form-label'>HSN Code:<span style={{ color: '#F62947' }}>*</span> </label>
                                                    <input
                                                        type='number'
                                                        name='HSN_code'
                                                        placeholder={placeholderText('Enter HSN Code')}
                                                        className='form-control'
                                                        onChange={(e) => onChangeInput(e)}
                                                        value={productValue.HSN_code}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-xl-4' >
                                            <div className='card'>
                                                <label className='form-label'>
                                                    {getFormattedMessage('product.input.multiple-image.label')}:<span style={{ color: '#F62947' }}>*</span></label>
                                                <MultipleImage product={singleProduct} fetchFiles={onChangeFiles}
                                                    transferImage={transferImage} />
                                            </div>
                                        </div>
                                        <hr/>
                                    </div>
                                </div>
                            </div>
                        </div>
                       <div className='col-md-12 mb-3'>
    <div className='row'>
        {singleProduct ? (
            ''
        ) : (
            <div className='col-md-6'>
                <div className='col-md-6 mb-3'>
                    <h1>{getFormattedMessage('Purchase Information')}: </h1>
                </div>
                <div className='col-md-8 mb-3' style={{ marginTop: "20px" }}>
                    <ReactSelect data={suppliers} onChange={onSupplierChange}
                    title={getFormattedMessage('supplier.title')}
                    defaultValue={productValue.supplier_id}

                    errors={errors['supplier_id']}
                    placeholder={placeholderText('purchase.select.supplier.placeholder.label')} />
                    </div>
                    <div className='col-md-8 mb-3'>
                    <label
                    className='form-label'>{getFormattedMessage('product.input.product-price.label')}: </label>
                    <InputGroup>
                    <input type='text' name='product_price' min={0}
                    className='form-control'
                    placeholder={placeholderText('product.input.product-price.placeholder.label')}
                    onKeyPress={(event) => decimalValidate(event)}
                    onChange={(e) => onChangeInput(e)}
                    value={productValue.product_price} />
                    <InputGroup.Text>{frontSetting.value && frontSetting.value.currency_symbol}</InputGroup.Text>
                    </InputGroup>
                    <span
                    className='text-danger d-block fw-400 fs-small mt-2'>{errors['product_price'] ? errors['product_price'] : null}</span>
                    </div>
            </div>
        )}


        <div className='col-md-6' >
            <div className='col-md-8 mb-3'>
                <h1>{getFormattedMessage('Pricing Details')}: </h1>
            </div>
            <div className='col-md-8 mb-3' >

                <div style={{marginTop:'5px'}}>
                    <label className='form-label' >{getFormattedMessage('MRP')}:</label>
                    <InputGroup>
                        <input type='text' name='MRP'
                            className='form-control'
                            placeholder={placeholderText('Enter Price')}
                            onKeyPress={(event) => decimalValidate(event)}
                            onChange={(e) => onChangeInput(e)}
                            value={productValue.MRP}
                        />
                        <InputGroup.Text>{frontSetting.value && frontSetting.value.currency_symbol}</InputGroup.Text>
                    </InputGroup>
                </div>

                <div>
                    <label className='form-label' style={{ marginTop: "10px" }}>
                        {getFormattedMessage('product.input.order-tax.label')}:</label>
                    <InputGroup>
                        <input type='text' name='order_tax' className='form-control'
                            placeholder={placeholderText('product.input.order-tax.placeholder.label')}
                            onKeyPress={(event) => decimalValidate(event)}
                            onChange={(e) => onChangeInput(e)}
                            min={0} pattern='[0-9]*' value={productValue.order_tax} />
                        <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup>
                    <span className='text-danger d-block fw-400 fs-small mt-2'>
                        {errors['order_tax'] ? errors['order_tax'] : null}
                    </span>
                       </div>
                </div>
            </div>
        </div>
            </div>
            <br />
            <hr />
                 <div className='col-xl-12 mb-3'>
                            <div className='col-md-8 mb-3' style={{ marginBottom: "50px" }} >
                                <h1 >{getFormattedMessage('add-stock.title')}: </h1>
                            </div>

                            <div className='col-md-4 mb-3'>
                    <ReactSelect data={warehouses} onChange={onWarehouseChange}
                        defaultValue={productValue.warehouse_id}
                        isWarehouseDisable={true}
                        title={getFormattedMessage('warehouse.title')}
                        errors={errors['warehouse_id']}
                        placeholder={placeholderText('purchase.select.warehouse.placeholder.label')} />

                    <div style={{ marginTop: "10px" }}> {/* Reduced margin-top */}
                        <label className='form-label'>{getFormattedMessage('product-quantity.add.title')}:</label>
                        <input type='number' name='add_stock'
                            className='form-control'
                            placeholder={placeholderText('product-quantity.add.title')}
                            onKeyPress={(event) => decimalValidate(event)}
                            onChange={(e) => onChangeInput(e)}
                            value={productValue.add_stock} min={1} />
                        <span className='text-danger d-block fw-400 fs-small mt-2'>
                            {errors['add_stock'] ? errors['add_stock'] : null}
                        </span>
                    </div>

                        <label
                         className='form-label'>{getFormattedMessage('product.input.stock-alert.label')}: </label>
                            <input type='number' name='stock_alert'   className='form-control'
                                placeholder={placeholderText('product.input.stock-alert.placeholder.label')}
                                 onKeyPress={(event) => decimalValidate(event)}
                                onChange={(e) => onChangeInput(e)}
                               value={productValue.stock_alert} min={0} />
                               <span
                                  className='text-danger d-block fw-400 fs-small mt-2'>{errors['stock_alert'] ? errors['stock_alert'] : null}</span>

                </div>
                <hr/>
                     </div>
                     <div>
      <div className='mb-3'>
     {!singleProduct&&<Form.Check
    type="switch"
    id="showColumnsSwitch"
    label="Batches"
    checked={showColumns}
    onChange={() => setShowColumns(!showColumns)}
  />} 
      </div>
      {showColumns && (
                     <div className='col-xl-12 mb-3'>
    <div className='row'>
        <div className='col-md-12 mb-3'>
            {/* <h1>{getFormattedMessage('Batches')}:</h1> */}
        </div>
        <div className='col-xl-4 mb-3'>
            <div className='row'>
            <div className='col-md-12 mb-3'>
                <label className='form-label'>{getFormattedMessage('Batch_No')}:</label>
                <input
                    type='text'
                    name='batch_no'
                    className='form-control'
                    value={batchValue.batch_no}
                    onChange={handleBatchNoChange}
                    placeholder={placeholderText('Enter Batch.No')}
                />
                </div>
            </div>
        </div>
        <div className='col-xl-4 mb-3'>
            <div className='row'>
                <div className='col-md-12 mb-3'>
                    <label className='form-label'>{getFormattedMessage('Stock')}:</label>
                   <input
                    type='number'
                    name='stock'
                    className='form-control'
                    placeholder='Enter stock'
                    min={0}
                    value={batchValue.stock}
                    onChange={handleStockChange}

                    />
        {errors['stock'] && <span className='error'>{errors['stock']}</span>}
                </div>
            </div>
        </div>
        <div className='col-xl-4 mb-3'>
            <div className='row'>
                <div className='col-md-12 mb-3'>
                <label className='form-label'>{getFormattedMessage('As of Date')}:</label>
                <br/>
               <DatePicker
                    selected={date}
                    value={batchValue.date}
                    className='form-control'
                    onChange={handleAsOfDateChange}
                    placeholder={placeholderText('product.input.stock-alert.placeholder.label')}
                  />
                </div>
            </div>
        </div>
        <div className='col-xl-4 mb-3'>
            <div className='row'>
                <div className='col-md-12 mb-3'>
                    <label className='form-label'>{getFormattedMessage('Sales Price')}:</label>
                    <InputGroup>
                    <input
                        type=''
                        name='sales_price'
                        className='form-control'
                        value={batchValue.sales_price}
                        onChange={handleSalesPriceChange}
                        placeholder={placeholderText('Enter Price')}
                        />
                        <InputGroup.Text>{frontSetting.value && frontSetting.value.currency_symbol}</InputGroup.Text>
                        </InputGroup>

              {errors['sales_price'] && <span className='error'>{errors['sales_price']}</span>}
                </div>
            </div>
        </div>
        <div className='col-xl-4 mb-3'>
            <div className='row'>
                <div className='col-md-12 mb-3'>
                <InputGroup className='flex-nowrap dropdown-side-btn'>
                         <ReactSelect
                           className='position-relative'
                            title={getFormattedMessage("Unit")}
                           placeholder={placeholderText('Choose Unit')}
                           defaultValue={productValue.product_unit}
                           value={productValue.product_unit}
                           data={baseUnits}
                           errors={errors['product_unit']}
                           onChange={handleProductUnitChange}
                           required={true} />
                          <Button onClick={() => showUnitModel(true)} className='position-absolute model-dtn'><FontAwesomeIcon
                        icon={faPlus} /></Button></InputGroup>
                </div>
            </div>
        </div>
        <div className='col-xl-4 mb-3'>
            <div className='row'>
                <div className='col-md-12 mb-3'>
                    <label className='form-label'>{getFormattedMessage('Purchase Price')}:</label>
                    <InputGroup>
                    <input
                        type=''
                        name='purchase_price'
                        className='form-control'
                        value={batchValue.purchase_price}
                        onChange={handlePurchasePriceChange}
                        placeholder={placeholderText('Enter Price')}
                    />
                    <InputGroup.Text>{frontSetting.value && frontSetting.value.currency_symbol}</InputGroup.Text>
                    </InputGroup>
                      {errors['purchase_price'] && <span className='error'>{errors['purchase_price']}</span>}
                </div>
                </div>
                </div>

                <div className='col-md-4 mb-3'>
                    <label className='form-label'>{getFormattedMessage('Exp.Date')}:</label>
                            <br/>
                <DatePicker
                    selected={batchValue.expiry_date}
                    onChange={handleExpiryDateChange}
                    className='form-control'
                    placeholderText='Select Date'
                />
                        {errors['expiry_date'] && <span className='error'>{errors['expiry_date']}</span>}
                </div>
                <div className='col-xl-4 mb-3'>
            <div className='row'>
                <div className='col-md-12 mb-3'>
                <label className='form-label'>{getFormattedMessage('Mfg.Date')}:</label>
                <br/>
                <DatePicker
                selected={batchValue.manufacture_date}
                onChange={handleManufactureDateChange}
                className='form-control'
                placeholderText='Select Date'
          />

                        {errors['manufacture_date'] && <span className='error'>{errors['manufacture_date']}</span>}
                </div>
            </div>
            </div>
    </div>
</div>
)
}
</div>
                        <div className={`accordion ${isOpen ? 'open' : ''}`}>
                             <div className="accordion" onClick={toggleAccordion}>
                                    <button className="accordion-button">Additional Details
                                    {isOpen ? '' : ''}
                                    </button>
                                </div>
                                 <br />
                                {isOpen && (
                                    <div className='row'>
                                        <div className='col-md-4 mb-3'>

                                            <ReactSelect
                                            title={getFormattedMessage('product.input.product-category.label')}
                                                placeholder={placeholderText('product.input.product-category.placeholder.label')}
                                                defaultValue={selectedProductCategory}
                                                value={productValue.product_category_id}
                                                data={productCategories}
                                                onChange={onProductCategoryChange}
                                                errors={errors['product_category_id']}
                                            />
                                        </div>
                                        <div className='col-md-4 mb-3'>

                                            <ReactSelect
                                            title={getFormattedMessage('product.input.brand.label')}
                                                placeholder={placeholderText('product.input.brand.placeholder.label')}
                                                defaultValue={selectedBrand}
                                                errors={errors['brand_id']}
                                                data={brands} onChange={onBrandChange}
                                                value={productValue.brand_id} />
                                        </div>
                                        {/* <div className='col-md-4 mb-3'>

                                            <ReactSelect
                                                 title={getFormattedMessage('product.input.barcode-symbology.label')}
                                                placeholder={placeholderText('product.input.barcode-symbology.placeholder.label')}
                                                defaultValue={selectedBarcode}
                                                errors={errors['barcode_symbol']}
                                                data={barcodes}
                                                onChange={onBarcodeChange}
                                                value={productValue.barcode_symbol}
                                            />
                                        </div> */}
                                        <div className='col-md-4 mb-3'>
                                            <InputGroup className='flex-nowrap dropdown-side-btn'>
                                                <ReactSelect
                                                    className='position-relative'
                                                    title={getFormattedMessage("product.input.product-unit.label")}
                                                    placeholder={placeholderText('product.input.product-unit.placeholder.label')}
                                                    defaultValue={productValue.product_unit}
                                                    value={productValue.product_unit}
                                                    data={baseUnits}
                                                    errors={errors['product_unit']}
                                                    onChange={handleProductUnitChange}
                                                    required={true} />
                                                <Button onClick={() => showUnitModel(true)} className='position-absolute model-dtn'><FontAwesomeIcon
                                                    icon={faPlus} /></Button></InputGroup>
                                        </div>
                                        <div className='col-md-4 mb-3'>
                                            <ReactSelect
                                                className='position-absolute'
                                                title={getFormattedMessage("product.input.sale-unit.label")}
                                                placeholder={placeholderText('product.input.sale-unit.placeholder.label')}
                                                value={isClearDropdown === false ? '' : productValue.sale_unit}
                                                data={saleUnitOption}
                                                errors={errors['sale_unit']}
                                                onChange={handleSaleUnitChange} />
                                        </div>
                                        <div className='col-md-4 mb-3'>
                                            <ReactSelect
                                                // className='position-relative'
                                                title={getFormattedMessage("product.input.purchase-unit.label")}
                                                placeholder={placeholderText('product.input.purchase-unit.placeholder.label')}
                                                value={isDropdown === false ? '' : productValue.purchase_unit}
                                                data={saleUnitOption}
                                                errors={errors['purchase_unit']}
                                                onChange={handlePurchaseUnitChange} />
                                        </div>

                                        <div className='col-md-4 mb-3'>
                                            <ReactSelect title={getFormattedMessage('product.input.tax-type.label')}
                                                multiLanguageOption={taxTypeFilterOptions}
                                                onChange={onTaxTypeChange}
                                                errors={errors['tax_type']}
                                                defaultValue={defaultTaxType}
                                                placeholder={placeholderText("product.input.tax-type.placeholder.label")}
                                            />
                                        </div>
                                        <div className='col-md-4 mb-3'>
                                            <label
                                                className='form-label'>{getFormattedMessage('product.input.quantity-limitation.label')}: </label>
                                            <input type='number' name='sale_quantity_limit'
                                                className='form-control'
                                                placeholder={placeholderText('product.input.quantity-limitation.placeholder')}
                                                onKeyPress={(event) => decimalValidate(event)}
                                                onChange={(e) => onChangeInput(e)}
                                                value={productValue.sale_quantity_limit} min={1} />
                                            <span
                                                className='text-danger d-block fw-400 fs-small mt-2'>{errors['stock_alert'] ? errors['stock_alert'] : null}</span>
                                        </div>

                                        <div className='col-md-10'>
                                            <label
                                                className='form-label'>{getFormattedMessage('globally.input.notes.label')}: </label>
                                            <textarea className='form-control' name='notes' rows={3}
                                                placeholder={placeholderText('globally.input.notes.placeholder.label')}
                                                onChange={(e) => onChangeInput(e)}
                                                value={productValue.notes ? productValue.notes === "null" ? '' : productValue.notes : ''} />
                                            <span
                                                className='text-danger d-block fw-400 fs-small mt-2'>{errors['notes'] ? errors['notes'] : null}</span>
                                        </div>
                                    </div>

                                )
                                }
                            </div>
                    </div>
                    <br />
                    <ModelFooter onEditRecord={singleProduct} onSubmit={(e) => { onSubmit(e) }}
                      />
                </Form>
            </div>
            {unitModel && <UnitsForm addProductData={addUnitsData} product_unit={productValue.product_unit}
                title={getFormattedMessage('unit.create.title')} show={unitModel}
                hide={setUnitModel} />}
        </div>
    )

}
    ;

const mapStateToProps = (state) => {
    const { brands, productCategories,products, units, totalRecord, suppliers, warehouses, productUnits, frontSetting } = state;
    console.log("stating products",state.products)
    return { brands, productCategories,products, units, totalRecord, suppliers, warehouses, productUnits, frontSetting };
};

export default connect(mapStateToProps, {
    addProduct,
    fetchProduct,
    editProduct,
    fetchAllBrands,
    fetchAllProductCategories,
    fetchUnits,
    productUnitDropdown,
    fetchAllWarehouses,
    fetchAllSuppliers,
    addBrand,
    addUnit
})(ProductForm);
