import apiConfig from '../../config/apiConfig';
import {apiBaseURL, toastType, topSellingActionType} from '../../constants';
import {addToast} from './toastAction';
import requestParam from '../../shared/requestParam';
import {setLoading} from './loadingAction';

export const fetchTopSellingReport = (filter = {}, isLoading = true) => async (dispatch) => {
    if (isLoading) {
        dispatch(setLoading(true))
    }
    let url = apiBaseURL.TOP_SELLING_PRODUCT_REPORT;
    if (!_.isEmpty(filter) && (filter.page || filter.pageSize || filter.search || filter.order_By || filter.created_at)) {
        url += requestParam(filter);
    }
   await apiConfig.get(url)
        .then((response) => {
            dispatch({type: topSellingActionType.TOP_SELLING_REPORT, payload: response.data.data})
            if (isLoading) {
                dispatch(setLoading(false))
            }
        })
        .catch(({response}) => {
            dispatch(addToast(
                {text: response.data.message, type: toastType.ERROR}));
        });
}
