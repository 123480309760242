import apiConfig from '../../config/apiConfigWthFormData';
import {apiBaseURL, Filters, productBatchActionType, toastType, Tokens} from '../../constants';
import {addToast} from './toastAction'
import {setTotalRecord, addInToTotalRecord, removeFromTotalRecord} from './totalRecordAction';
import requestParam from '../../shared/requestParam';
import {setLoading} from './loadingAction';
import {getFormattedMessage} from '../../shared/sharedMethod';
import {setSavingButton} from "./saveButtonAction";
import axios from 'axios';
import { environment } from '../../config/environment';


const prepareFormData=(productBatchesData)=>{
    const formData = new FormData();
    formData.append('product_id', productBatchesData.product_id);
    formData.append('batch_no', productBatchesData.batch_no);
    formData.append('sales_price', productBatchesData.sales_price);
    formData.append('purchase_price', productBatchesData.purchase_price);
    formData.append('stock', productBatchesData.stock);
    formData.append('base_unit', productBatchesData.base_unit);
    formData.append('expiry_date)', productBatchesData.expiry_date);
    formData.append('manufacture_date', productBatchesData.manufacture_date);
    return formData;

   }

  export const addProductBatches = (productBatchesData) => async (dispatch) => {
  console.log("batches dat in product",productBatchesData)

  const batchData= {
      batch_no:productBatchesData.batch_no,
      sales_price:productBatchesData.sales_price,
      purchase_price:productBatchesData.purchase_price,
      stock:productBatchesData.stock,
      base_unit:productBatchesData.base_unit,
      manufacture_date:productBatchesData.manufacture_date,
      expiry_date:productBatchesData.expiry_date,
      product_id:productBatchesData.product_id
       
             }
             const token = localStorage.getItem('auth_token');
           console.log('auth_token',token)

    var config = {
        method: 'post',
        url: environment.URL+"/api/product_batches",
        headers: { 
          'Authorization': `Bearer ${token}`, 
          'Content-Type': 'application/json'
        },
        data : batchData
      };

        dispatch(setSavingButton(true));

        axios(config).then(async(response)=>{
        dispatch({ type: productBatchActionType.ADD_PRODUCT_BATCH, payload: response.data.data });
        dispatch(addInToTotalRecord(1));
        dispatch(setSavingButton(false));
      
       
       
       }).catch(({response}) => {
      
         //dispatch(addToast({ text: 'create batch error', type: toastType.ERROR }));
       });
      //  try {
      //    const response = await apiConfig.post(apiBaseURL.PRODUCT_BATCH, prepareFormData (productBatchesData));
        
      //  } catch ({ response }) {
      //    dispatch(setSavingButton(false));
      //    dispatch(addToast({ text: response.data.message, type: toastType.ERROR }));
      //  }
     };

     export const fetchProductBatches = (productId) => async (dispatch) => {
      console.log('fetch product id',productId)
      try {
        const response = await apiConfig.get(apiBaseURL.PRODUCT_BATCH + '/' + productId);
        console.log('response fetch data',response.data)
        dispatch({ type: productBatchActionType.FETCH_PRODUCT_BATCHES, payload: response.data });
      } catch ({ response }) {
        //dispatch(addToast({ text: response.data.message, type: toastType.ERROR }));
      }
    }; 
      export const editProductBatch = (batchId, productBatchesData, navigate) => async (dispatch) => {
        console.log("batch id fetching", productBatchesData)
        dispatch(setSavingButton(true))
        apiConfig.put(apiBaseURL.PRODUCT_BATCH + '/' + batchId, productBatchesData)
            .then((response) => {
                navigate('/app/products')
                dispatch(addToast({text: getFormattedMessage('Product Batch updated Successfully')}));
                dispatch({type: productBatchActionType.EDIT_PRODUCT_BATCH, payload: response.data.data});
                dispatch(setSavingButton(false))
            })
            .catch(({response}) => {
                dispatch(setSavingButton(false))
                dispatch(addToast(
                    {text: response.data.message, type: toastType.ERROR}));
            });
    };

