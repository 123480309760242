import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { connect, useDispatch } from 'react-redux';
import MasterLayout from '../MasterLayout';
import TabTitle from '../../shared/tab-title/TabTitle';
import ReactDataTable from '../../shared/table/ReactDataTable';
import DeleteCreditNote from './DeleteCreditNote';
import { currencySymbolHendling, getFormattedDate, getFormattedMessage, placeholderText } from '../../shared/sharedMethod';
import { salePdfAction } from '../../store/action/salePdfAction';
import { fetchFrontSetting } from '../../store/action/frontSettingAction';
import { fetchSalePayments } from "../../store/action/salePaymentAction";
import TopProgressBar from "../../shared/components/loaders/TopProgressBar";
import { fetchCreditNote } from '../../store/action/salesCreditNote';
import ActionButton from '../../shared/action-buttons/ActionButton';
import { useNavigate } from 'react-router';
const CreditNote = (props) => {
    const {
        fetchCreditNote,
        totalRecord,
        isLoading,
        salePdfAction,
        fetchFrontSetting,
        frontSetting,
        isCallSaleApi,
        allConfigData,
        creditNote
    } = props;
    console.log("record",totalRecord);
    const [deleteModel, setDeleteModel] = useState(false);
    const [isShowPaymentModel, setIsShowPaymentModel] = useState(false);
    const [isCreatePaymentOpen, setIsCreatePaymentOpen] = useState(false);
    const [isDelete, setIsDelete] = useState(null);
    const [tableArray, setTableArray] = useState([])
    const navigate= useNavigate();
    useEffect(() => {
        fetchFrontSetting();
    }, []);

    const currencySymbol = frontSetting && frontSetting.value && frontSetting.value.currency_symbol

    const onChange = (filter) => {
        fetchCreditNote(filter, true);
    };

    const goToEdit = (item) => {
         const id = item.id;
        window.location.href = '#/app/credit-note/edit/' + id;
    };

    // delete  function
    const onClickDeleteModel = (isDelete = null) => {
         setDeleteModel(!deleteModel);
         setIsDelete(isDelete);
    };
    const dispatch = useDispatch()

    const onShowPaymentClick = (item) => {
        setIsShowPaymentModel(!isShowPaymentModel);
        setCreatePaymentItem(item)
        if (item) {
            dispatch(fetchSalePayments(item.id))
        }
    };

    const onCreatePaymentClick = (item) => {
        setIsCreatePaymentOpen(!isCreatePaymentOpen);
        setCreatePaymentItem(item)
        if (item) {
            dispatch(fetchSalePayments(item.id))
        }
    };

    //sale details function
    const goToDetailScreen = (ProductId) => {
       console.log("data");
       window.location.href = '#/app/credit-note/detail/' + ProductId;
    };
  

    //onClick pdf function
    const onPdfClick = (id) => {
        salePdfAction(id);
    };

    const onCreateSaleReturnClick = (item) => {
        const id = item.id;
        window.location.href = item.is_return === 1 ? '#/app/sales/return/edit/' + id : "#/app/sales/return/" + id;
    };

    const itemsValue = creditNote.length >= 0 && creditNote.map(sale => ({
        date: getFormattedDate(sale.attributes.date, allConfigData && allConfigData),
        time: moment(sale.attributes.created_at).format('LT'),
        reference_code: sale.attributes.reference_code,
        customer_name: sale.attributes.customer_name,
        warehouse_name: sale.attributes.warehouse_name,
        status: sale.attributes.status,
        payment_status: sale.attributes.payment_status,
        payment_type: sale.attributes.payment_type,
        grand_total: sale.attributes.grand_total,
        paid_amount: sale.attributes.paid_amount ? sale.attributes.paid_amount : 0.00.toFixed(2),
        id: sale.id,
        //currency: currencySymbol,
        is_return: sale.attributes.is_return,
    }));

    console.log("itemsvaluesss",itemsValue);

    useEffect(() => {
        const grandTotalSum = () => {
            let x = 0;
            itemsValue.length && itemsValue.map((item) => {
                x = x + Number(item.grand_total);
                return x;
            });
            return x;
        }
        const paidTotalSum = (itemsValue) => {
            let x = 0;
            itemsValue.length && itemsValue.map((item) => {
                x = x + Number(item.paid_amount);
                return x;
            });
            return x;
        }
        if (creditNote.length) {
            const newObject = itemsValue.length && {
                date: "",
                time: "",
                reference_code: "Total",
                customer_name: "",
                warehouse_name: "",
                status: "",
                payment_status: "",
                payment_type: "",
                grand_total: grandTotalSum(itemsValue),
                paid_amount: paidTotalSum(itemsValue),
                id: "",
                currency: currencySymbol
            }
            const newItemValue = itemsValue.length && newObject && itemsValue.concat(newObject)
            const latestArray = newItemValue.map((item) => item)
            newItemValue.length && setTableArray(latestArray)
        }
    }, [creditNote])

    const columns = [
        // {
        //     name: getFormattedMessage('dashboard.recentSales.reference.label'),
        //     sortField: 'reference_code',
        //     sortable: false,
        //     cell: row => {
        //         return row.reference_code === "Total" ?
        //             <span className="fw-bold fs-4">{getFormattedMessage("pos-total.title")}</span> :
        //             <span className='badge bg-light-danger'>
        //                 <span>{row.reference_code}</span>
        //             </span>
        //     }
        // },
        {
            name: getFormattedMessage('customer.title'),
            selector: row => row.customer_name,
            sortField: 'customer_name',
            sortable: false,
        },
        {
            name: getFormattedMessage('warehouse.title'),
            selector: row => row.warehouse_name,
            sortField: 'warehouse_name',
            sortable: false,
        },
        {
            name: getFormattedMessage('purchase.select.status.label'),
            sortField: 'status',
            sortable: false,
            cell: row => {
                return (
                    row.status === 1 &&
                    <span className='badge bg-light-success'>
                        <span>{getFormattedMessage("status.filter.received.label")}</span>
                    </span> ||
                    row.status === 2 &&
                    <span className='badge bg-light-primary'>
                        <span>{getFormattedMessage("status.filter.pending.label")}</span>
                    </span> ||
                    row.status === 3 &&
                    <span className='badge bg-light-warning'>
                        <span>{getFormattedMessage("status.filter.ordered.label")}</span>
                    </span>
                )
            }
        },
        {
            name: getFormattedMessage('purchase.grant-total.label'),
            sortField: 'grand_total',
            cell: row => {
                return row.reference_code === "Total" ? <span
                    className="fw-bold fs-4">{currencySymbolHendling(allConfigData, row.currency, row.grand_total)}</span> :
                    <span>{currencySymbolHendling(allConfigData, row.currency, row.grand_total)}</span>
            },
            sortable: true,
        },
        // {
        //     name: getFormattedMessage('dashboard.recentSales.paid.label'),
        //     sortField: 'paid_amount',
        //     cell: row => {
        //         return row.reference_code === "Total" ? <span
        //             className="fw-bold fs-4">
        //             {currencySymbolHendling(allConfigData, row.currency, row.paid_amount)}</span> :
        //             <span>{currencySymbolHendling(allConfigData, row.currency, row.paid_amount)}</span>
        //     },
        //     sortable: true,
        // },
        {
            name: getFormattedMessage('dashboard.recentSales.paymentStatus.label'),
            sortField: 'payment_status',
            sortable: false,
            cell: row => {
                return (
                    row.payment_status === 1 &&
                    <span className='badge bg-light-success'>
                        <span>{getFormattedMessage("payment-status.filter.paid.label")}</span>
                    </span> ||
                    row.payment_status === 2 &&
                    <span className='badge bg-light-danger'>
                        <span>{getFormattedMessage("payment-status.filter.unpaid.label")}</span>
                    </span> ||
                    row.payment_status === 3 &&
                    <span className='badge bg-light-warning'>
                        {/*<span>{getFormattedMessage("payment-status.filter.unpaid.label")}</span>*/}
                        <span>{getFormattedMessage("payment-status.filter.partial.label")}</span>
                    </span>
                )
            }
        },
        {
            name: getFormattedMessage('select.payment-type.label'),
            sortField: 'payment_type',
            sortable: false,
            cell: row => {
                return (
                    row.payment_type === 1 &&
                    <span className='badge bg-light-primary'>
                        <span>{getFormattedMessage('cash.label')}</span>
                    </span> ||
                    row.payment_type === 2 &&
                    <span className='badge bg-light-primary'>
                        <span>{getFormattedMessage('payment-type.filter.cheque.label')}</span>
                    </span> ||
                    row.payment_type === 3 &&
                    <span className='badge bg-light-primary'>
                        <span>{getFormattedMessage('payment-type.filter.bank-transfer.label')}</span>
                    </span> ||
                    row.payment_type === 4 &&
                    <span className='badge bg-light-primary'>
                        <span>{getFormattedMessage('payment-type.filter.other.label')}</span>
                    </span>
                )
            }
        },
        {
            name: getFormattedMessage('globally.react-table.column.created-date.label'),
            selector: row => row.date,
            sortField: 'date',
            sortable: true,
            cell: row => {
                return (
                    row.date && <span className='badge bg-light-info'>
                        <div className='mb-1'>{row.time}</div>
                        <div>{row.date}</div>
                    </span>
                )
            }
        },
        {
            name: getFormattedMessage('react-data-table.action.column.label'),
            right: true,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '120px',
            cell: row => row.reference_code === "Total" ? null : <ActionButton item={row} goToEditProduct={goToEdit} isEditMode={true} 
                onClickDeleteModel={onClickDeleteModel} 
                title={getFormattedMessage('credit_note')}
                isViewIcon={true} goToDetailScreen={goToDetailScreen}
                
            />
        }
    ];

    return (
        <MasterLayout>
            <TopProgressBar />
            <TabTitle title={placeholderText('Credit.title')} />
            <div className='Credit_Note_table'>
            <ReactDataTable columns={columns} items={tableArray} to='#/app/credit-note/create'
                ButtonValue={getFormattedMessage('Create Credit Note')}
                isCallSaleApi={isCallSaleApi}
                 onChange={onChange} totalRows={totalRecord} 
                 goToEdit={goToEdit}
                isLoading={isLoading} />
            </div>
            <DeleteCreditNote onClickDeleteModel={onClickDeleteModel} deleteModel={deleteModel} onDelete={isDelete} />
        </MasterLayout>
    )
};

const mapStateToProps = (state) => {
    console.log('stateCredit',state);
    const { sales, totalRecord, isLoading, frontSetting, isCallSaleApi, allConfigData ,creditNote} = state;
    return { sales, totalRecord, isLoading, frontSetting, isCallSaleApi, allConfigData,creditNote };
};

export default connect(mapStateToProps, { fetchCreditNote, salePdfAction, fetchFrontSetting })(CreditNote);
