import React from 'react';

const Footer = (props) => {
    const {allConfigData, frontSetting} = props
    return (
        <footer className='border-top w-100 pt-4 mt-7 d-flex justify-content-between'>
            <p className=' text-gray-600' style={{fontSize:'12px'}}>All Rights Reserved (C) {new Date().getFullYear()}
                <a href='#' className='text-decoration-none text-gray-600'> Adol</a>
            </p>
            {/* <div className="fs-6 text-gray-600">
                {allConfigData && allConfigData.is_version === "1" ? "v" + allConfigData.version : ""}
            </div> */}
        </footer>
    )
};

export default Footer;