import {Tokens, errorMessage} from '../constants';
import {environment} from './environment'

export default {
    setupInterceptors: (axios, isToken = false, isFormData = false) => {
        axios.interceptors.request.use(
            (config) => {
              if (!isToken) {
                return config;
            }
            let adminAuthToken = localStorage.getItem(Tokens.ADMIN);
            if (isToken) {
              if(!adminAuthToken){
                if (!window.location.href.includes('login') && !window.location.href.includes('reset-password') && !window.location.href.includes('forgot-password')) {
                  window.location.href = environment.APP_URL + '#/' + 'login';
                }
              }
              config.headers['Authorization'] = `Bearer ${adminAuthToken}`;
            }

            if (isFormData) {
              config.headers['Content-Type'] = 'multipart/form-data';
            }

            if (config.method === 'options' || config.method === 'OPTIONS') {
              console.log('OPTIONS request intercepted');
            }
              return config;
            },
            (error) => {
              return Promise.reject(error);
            }
        );

        axios.interceptors.response.use(
          (config) => {
              // Check if the request method is OPTIONS
              if (config.method === 'options' || config.method === 'OPTIONS') {
                  console.log('options handler')
                // Modify the request to set specific headers or data for OPTIONS request
                // For example, you might set the necessary headers like 'Access-Control-Request-Method'
                // or 'Access-Control-Request-Headers'
                config.headers = {
                  ...config.headers,
                  'Access-Control-Request-Method': 'GET, POST, PUT, DELETE', // List allowed methods
                  'Access-Control-Request-Headers': 'Authorization, Content-Type', // List allowed headers
                };
              }
              return config;
          },
          response => successHandler(response),
          error => errorHandler(error)
        );
        const errorHandler = (error) => {
            if (error.response.status === 401
                || error.response.data.message === errorMessage.TOKEN_NOT_PROVIDED
                || error.response.data.message === errorMessage.TOKEN_INVALID
                || error.response.data.message === errorMessage.TOKEN_INVALID_SIGNATURE
                || error.response.data.message === errorMessage.TOKEN_EXPIRED) {
                localStorage.removeItem(Tokens.ADMIN);
                localStorage.removeItem(Tokens.USER);
                localStorage.removeItem(Tokens.GET_PERMISSIONS);
               window.location.href = environment.APP_URL + '#' + '/login';  
            }else if(error.response.status === 403 || error.response.status === 404) {
                window.location.href = environment.APP_URL + '#' + '/pos';
            }  else {
                console.log('errIntercept',error)
                return Promise.reject(error)
            }
        };
        const successHandler = (response) => {
            return Promise.resolve({response});
        };
    }
};
