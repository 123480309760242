import React, {useEffect,useState} from 'react';
import {connect} from 'react-redux';
import {Image, Table} from 'react-bootstrap-v5';
import {useParams} from 'react-router-dom';
import Carousel from 'react-elastic-carousel';
import MasterLayout from '../MasterLayout';
import TabTitle from '../../shared/tab-title/TabTitle';
import {fetchProduct} from '../../store/action/productAction';
import {fetchProductBatches} from '../../store/action/productBatchAction';
import DeleteProduct from './DeleteProduct';
import ActionButton from '../../shared/action-buttons/ActionButton';
import HeaderTitle from '../header/HeaderTitle';
import user from '../../assets/images/brand_logo.png';
import {getFormattedMessage, placeholderText, currencySymbolHendling} from '../../shared/sharedMethod';
import Spinner from "../../shared/components/loaders/Spinner";
import DataTable from 'react-data-table-component';
import { format } from 'date-fns';
import TopProgressBar from "../../shared/components/loaders/TopProgressBar";

const ProductDetail = props => {
 const {products,fetchProduct, isLoading, frontSetting, allConfigData,productBatchesData,fetchProductBatches,} = props;
 const {id} = useParams();
 console.log("items batch",id)
 const [deleteModel, setDeleteModel] = useState(false);
 const [isDelete, setIsDelete] = useState(null);
 const result = products && products.reduce((obj, cur) => ({...obj, [cur.type]: cur}), {})
 const product = result.products
 console.log('productBatch details',productBatchesData)

 useEffect(() => {
 fetchProductBatches(id);
 }, [id]);

 useEffect(() => {
 fetchProduct(id);
 }, []);

 const onClickDeleteModel = (isDelete = null) => {
 setDeleteModel(!deleteModel);
 setIsDelete(isDelete);
 };

 const goToEditProductBatch = (item) => {
 console.log("items",item)
 const id = item.product_id;
 console.log("items for id",item.product_id)
 window.location.href = '#/app/products/editBatch/' + 19;

 };



 const sliderImage = product && product.attributes && product.attributes.images.imageUrls && product.attributes.images.imageUrls.map((img) => img)
 const warehouse = product && product.attributes && product.attributes.warehouse && product.attributes.warehouse.map((item) => item)

 const currencySymbol = frontSetting && frontSetting.value && frontSetting.value.currency_symbol
 const itemsValueBatches = productBatchesData.length === 1 && productBatchesData.map((batch) => {
 console.log('batch datas',batch)
 return {
 product_id:batch.product_id,
 date: batch.created_at || '',
 batch_no: batch.batch_no || '',
 sales_price: batch.sales_price || 0,
 purchase_price: batch.purchase_price || 0,
 stock: batch.stock || 0,
 unit: batch.base_unit || '',
 expiry_date: batch.expiry_date || '',
 manufacture_date: batch.manufacture_date || '',
 currency: currencySymbol
 };
 });
 console.log("batch details passed",productBatchesData)

 const batchColumns = [
 {
 name: 'Date',
 selector: 'created_at',
 sortField: 'created_at',
 sortable: true,
 cell: () => {
 const currentDate = new Date();
 const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;

 return (
 <span className='badge bg-light-info'>
 <div className='mb-1'>{formattedDate}</div>
 </span>
 );
 },
 },

 {
 name: getFormattedMessage('Batch No'),
 selector: 'batch_no',
 sortField: 'batch_no',
 sortable: false,
 cell: (row) => {
 return (
 <span className='badge bg-light-info'>
 <div className='mb-1'>{row.batch_no}</div>
 </span>
 );
 },
 },
 {
 name: getFormattedMessage('Stock'),
 selector: 'stock',
 sortField: 'stock',
 sortable: false,
 cell: (row) => {
 return (
 <span className='badge bg-light-info'>
 <div className='mb-1'>{row.stock}</div>
 </span>
 );
 },
 },
 {
 name: getFormattedMessage('Sales Price'),
 selector: 'sales_price',
 sortField: 'sales_price',
 sortable: true,
 cell: (row) => {
 return (
 <span className='badge bg-light-info'>
 <div className='mb-1'>{currencySymbolHendling(allConfigData, row.currency, row.sales_price)}</div>
 </span>
 );
 },
 },
 {
 name: getFormattedMessage('Purchase Price'),
 selector: 'purchase_price',
 sortField: 'purchase_price',
 sortable: true,
 cell: (row) => {
 return (
 <span className='badge bg-light-info'>
 <div className='mb-1'>{currencySymbolHendling(allConfigData, row.currency, row.purchase_price)}</div>
 </span>
 );
 },
 },
 {
 name: getFormattedMessage('Unit'),
 selector: 'product_unit',
 sortField: 'product_unit',
 sortable: true,
 cell: (row) => {
 return (
 <span className='badge bg-light-success'>
 <div className='mb-1'>2</div>
 </span>
 );
 },
 },
 {
 name: 'Expiry Date',
 selector: 'expiry_date',
 sortField: 'expiry_date',
 sortable: true,
 cell: (row) => {
 return (
 <span className='badge bg-light-info'>
 <div className='mb-1'>31-10-2023</div>
 </span>
 );
 },
 },
 {
 name: 'Manufacture Date',
 selector: 'manufacture_date',
 sortField: 'manufacture_date',
 sortable: true,
 cell: (row) => {
 return (
 <span className='badge bg-light-info'>
 <div className='mb-1'>22-10-2023</div>
 </span>
 );
 },
 },
 {
 name: getFormattedMessage('react-data-table.action.column.label'),

 ignoreRowClick: true,
 allowOverflow: true,
 button: true,
 width: '100px',
 cell: row => {
 console.log('each rows',row)
 return(
 <ActionButton item={row} goToEditProduct={goToEditProductBatch} isEditMode={true}
 onClickDeleteModel={onClickDeleteModel} />
 )

 }


 }

 ];
 const BatchData = [
 {
 created_at: '2023-09-27',
 batch_no: 'ABC123',
 stock: 4,
 sales_price: 100,
 purchase_price: 100,
 product_unit: 'Unit',
 expiry_date: '2023-10-31',
 manufacture_date: '2023-10-22',
 
 },
 
 ]

 return (
 <MasterLayout>
 <TopProgressBar/>
 <HeaderTitle title={getFormattedMessage('product.product-details.title')} to='/app/products'/>
 <TabTitle title={placeholderText('product.product-details.title')}/>
 <div className='card card-body'>
 <div className='row'>
 {isLoading ?
 <Spinner /> : <>
 <div className='col-md-12'>
 <div className='d-inline-block text-center'>
 <Image
 src={product && product.attributes && product.attributes.barcode_url}
 alt={product && product.attributes && product.attributes.name}
 className='product_brcode'/>
 <div
 className='mt-3'>{product && product.attributes && product.attributes.code}</div>
 </div>
 </div>
 <div className='col-xxl-7'>
 <table className='table table-responsive gy-7'>
 <tbody>
 <tr>
 <th className='py-4' scope='row'>{getFormattedMessage('product.product-details.code-product.label')}</th>
 <td className='py-4'>{product && product.attributes && product.attributes.code}</td>
 </tr>
 <tr>
 <th className='py-4' scope='row'>{getFormattedMessage('product.title')}</th>
 <td className='py-4'>{product && product.attributes && product.attributes.name}</td>
 </tr>
 <tr>
 <th className='py-4' scope='row'>{getFormattedMessage('Product Type')}</th>
 <td className='py-4'>{product && product.attributes && product.attributes.product_type}</td>
 </tr>
 <tr>
 <th className='py-4' scope='row'>{getFormattedMessage('product.product-details.category.label')}</th>
 <td className='py-4'> {product && product.attributes && product.attributes.product_category_name}</td>
 </tr>
 <tr>
 <th className='py-4' scope='row'>{getFormattedMessage('product.input.brand.label')}</th>
 <td className='py-4'>{product && product.attributes && product.attributes.brand_name}</td>
 </tr>
 <tr>
 <th className='py-4' scope='row'>{getFormattedMessage('product.product-details.cost.label')}</th>
 <td className='py-4'>{currencySymbolHendling(allConfigData, frontSetting.value && frontSetting.value.currency_symbol, product && product.attributes && product.attributes.product_cost)}</td>
 </tr>
 <tr>
 <th className='py-4' scope='row'>{getFormattedMessage('product.table.price.column.label')}</th>
 <td className='py-4'>{currencySymbolHendling(allConfigData, frontSetting.value && frontSetting.value.currency_symbol, product && product.attributes && product.attributes.product_price)}</td>
 </tr>
 <tr>
 <th className='py-4' scope='row'>{getFormattedMessage('product.product-details.unit.label')}</th>
 {product && product.attributes && product.attributes.product_unit_name &&
 <td className='py-4'><span className='badge bg-light-success'><span>{product.attributes.product_unit_name?.name}</span></span></td>}
 </tr>
 <tr>
 <th className='py-4' scope='row'>{getFormattedMessage('product.product-details.tax.label')}</th>
 <td className='py-4'>{product && product.attributes && product.attributes.order_tax ? product.attributes.order_tax : 0} %</td>
 </tr>
 <tr>
 <th className='py-4' scope='row'>{getFormattedMessage('product.input.stock-alert.label')}</th>
 <td className='py-4'>{product && product.attributes && product.attributes.stock_alert}</td>
 </tr>
 <tr>
 <th className='py-4' scope='row'>{getFormattedMessage('MRP')}</th>
 <td className='py-4'>{product && product.attributes && product.attributes.MRP}</td>
 </tr>
 </tbody>
 </table>
 </div>
 <div className='col-xxl-5 d-flex justify-content-center m-auto'>
 {sliderImage && sliderImage.length !== 0 ? <Carousel>
 {sliderImage.length !== 0 && sliderImage.map((img, i) => {
 return (<div key={i}>
 <Image src={img} width='413px'/>
 </div>)
 })}
 </Carousel> : <div>
 <Image src={user} width='413px'/>
 </div>}
 </div>
 </>}
 </div>
 </div>
 {warehouse && warehouse.length !== 0 ?
 <div className='card card-body mt-2'>
 <div>
 <Table responsive="md">
 <thead>
 <tr>
 <th>{getFormattedMessage('dashboard.stockAlert.warehouse.label')}</th>
 <th>{getFormattedMessage('dashboard.stockAlert.quantity.label')}</th>
 </tr>
 </thead>
 <tbody>
 {warehouse && warehouse.map((item, index) => {
 return(
 <tr key={index}>
 <td className='py-4'>{item.name}</td>
 <td className='py-4'>
 <div>
 <div className='badge bg-light-info me-2'><span>{item.total_quantity}</span></div>
 {product.attributes.product_unit === '1' && <span className='badge bg-light-success me-2'><span>{getFormattedMessage("gram")}</span></span>
 ||product.attributes.product_unit === '2' && <span className='badge bg-light-success me-2'><span>{getFormattedMessage("unit.filter.kilogram.label")}</span></span>
 ||product.attributes.product_unit === '3' && <span className='badge bg-light-success me-2'><span>{getFormattedMessage("milli-litre")}</span></span>
 ||product.attributes.product_unit === '4' && <span className='badge bg-light-success me-2'><span>{getFormattedMessage("kilo-litre")}</span></span>
 || product.attributes.product_unit === '5' && <span className='badge bg-light-primary me-2'><span>{getFormattedMessage("meter")}</span></span>
 || product.attributes.product_unit === '6' && <span className='badge bg-light-warning me-2'><span>{getFormattedMessage("piece")}</span></span>}
 </div>
 </td>
 </tr>
 )
 })}
 </tbody>


 </Table>
 </div>
 </div> : ''
 }
 <DeleteProduct onClickDeleteModel={onClickDeleteModel} deleteModel={deleteModel} onDelete={isDelete} />
 <h2 style={{marginTop:"15px"}}> Batches </h2>
 <DataTable columns={batchColumns} data={BatchData } style={{marginTop:"10px"}}/>
 </MasterLayout>
 )
};

const mapStateToProps = (state) => {
 console.log('product batch data in state',state)
 const {products,productBatchesData, isLoading, frontSetting, allConfigData} = state;
 return {products,productBatchesData, isLoading, frontSetting, allConfigData}
};

export default connect(mapStateToProps, {fetchProduct,fetchProductBatches})(ProductDetail);