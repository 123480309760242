import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom'
//import { fetchProduct } from '../../store/action/productAction';
import BatchForm from './BatchForm';
import HeaderTitle from '../header/HeaderTitle';
import MasterLayout from '../MasterLayout';
// import { productUnitDropdown } from '../../store/action/productUnitAction';
// import { fetchAllunits } from '../../store/action/unitsAction';
import { getFormattedMessage } from '../../shared/sharedMethod';
import TopProgressBar from "../../shared/components/loaders/TopProgressBar";
import { Filters } from '../../constants';
import { fetchAllBaseUnits } from "../../store/action/baseUnitsAction";
import { fetchProductBatches } from '../../store/action/productBatchAction';

const EditProductBatch = (props) => {
    const {  products,productBatchesData,fetchAllBaseUnits, base } = props;
    const { id } = useParams();
    console.log("product details",products);
    console.log("product batches data",productBatchesData);
    useEffect(() => {
        console.log('use effects call in edit product')
        fetchAllBaseUnits();
        // fetchProduct(id);
        fetchProductBatches(id);
    }, []);

    // const itemsValueBatches = productBatchesData.length === 1 && productBatchesData.map(batch => ({
    //     id: batch.id,
    //     product_id:batch.product_id,
    //       batch_no: batch.batch_no || '',
    //       sales_price: batch.sales_price || 0,
    //       purchase_price: batch.purchase_price || 0,
    //       stock: batch.stock || 0,
    //       unit: batch.base_unit || '',
    //       expiry_date: batch.expiry_date || '',
    //       manufacture_date: batch.manufacture_date || '',
    //       

    //   }));
    //  console.log("item values for batch",itemsValueBatches)

    // const getProductUnit = itemsValue && base.filter((fill) => Number(fill?.id) === Number(itemsValue[0]?.product_unit))


    const BatchData = [
        {
           created_at: '2023-09-27',
           batch_no: 'ABC123',
          stock: 4,
          sales_price: 100,
          purchase_price: 100,
          product_unit: 'Unit',
          expiry_date: '2023-10-31',
          manufacture_date: '2023-10-22',
      
        },
 
      ];
    return (
        <MasterLayout>
            <TopProgressBar />
            <HeaderTitle title={getFormattedMessage('Edit Product Batch')}to={`/app/products/detail/${id}`}  />
           <BatchForm productBatch={BatchData} />
        </MasterLayout>
    )
};

const mapStateToProps = (state) => {
    console.log("state for checking batch",state)
    const { products,productBatchesData, base } = state;
    return {products,productBatchesData,base }
};

export default connect(mapStateToProps, {fetchAllBaseUnits,fetchProductBatches })(EditProductBatch);

