import React from 'react';
import {Button} from 'react-bootstrap-v5';

const TableButton = ({ButtonValue, to}) => {
    return(
        <div className='text-end me-1  '>
            <Button type='button'   style={{backgroundColor:'#21263c',border:'1px solid #21263c',color:'white',fontSize:'12px'}} href={to}>{ButtonValue}</Button>
        </div>
    )
}

export default TableButton;
