import React from 'react';
import { Link } from 'react-router-dom';
import { getFormattedMessage } from '../../shared/sharedMethod';
import {useLocation} from 'react-router-dom';

const HeaderTitle = (props) => {
    const { title, to, editLink } = props;
    const location =useLocation ();


    return (
        <div className='d-md-flex align-items-center justify-content-between mb-5'>
            {title ? <h1 className='mb-0'>{title}</h1> : ''}
            <div className='text-end mt-4 mt-md-0'>
                {editLink ? (
                    <Link to={editLink} className='btn btn-outline-primary me-2'>
                        {getFormattedMessage('globally.edit-btn')}
                    </Link>
                ) : null}

                {/* <Link to='/app/products' className='btn btn-primary me-2'>
                    {getFormattedMessage('globally.save-btn')}
                    </Link>
                <Link to='/app/products' className='btn btn-secondary me-3'>
                    {getFormattedMessage('globally.cancel-btn')}
                </Link> */}
                {to ? (
                    <Link to={to} className='btn btn-outline-primary'>
                        {getFormattedMessage('globally.back-btn')}
                    </Link>
                ) : null}
            </div>
        </div>
    );
};

export default HeaderTitle;
