import apiConfigWithoutToken from '../../config/apiConfigWithoutToken';
import {apiBaseURL, frontSettingActionType, toastType} from '../../constants';
import {addToast} from './toastAction';
import {setLoading} from "./loadingAction";

export const fetchFrontSetting = () => async (dispatch) => {
    apiConfigWithoutToken.get(apiBaseURL.FRONT_SETTING)
        .then((response) => {
            console.log('fetchFrontSetting',response);
            if(response.data){
                dispatch({type: frontSettingActionType.FETCH_FRONT_SETTING, payload: response.data.data});
            }
           
        })
        .catch((response) => {
            dispatch(addToast(
                {text: response.data.message, type: toastType.ERROR}));
        });
}
