import React, {useEffect} from 'react';
import {Col, Row} from 'react-bootstrap';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faShoppingCart, faCartPlus, faArrowRight, faArrowLeft, faDollar, faSquareMinus, faMoneyBill, faBars} from '@fortawesome/free-solid-svg-icons';
import {getFormattedMessage} from '../../shared/sharedMethod';
import {todaySalePurchaseCount} from '../../store/action/dashboardAction';
import Widget from '../../shared/Widget/Widget';
import {useNavigate} from 'react-router-dom';
import {fetchAllSalePurchaseCount} from "../../store/action/allSalePurchaseAction";
import { NavDropdown } from 'react-bootstrap-v5';
import { useState } from 'react';


const TodaySalePurchaseCount = (props) => {
    const {todaySalePurchaseCount, todayCount, frontSetting, config, allSalePurchase, fetchAllSalePurchaseCount, allConfigData} = props;
    const navigate = useNavigate()

    useEffect(() => {
        todaySalePurchaseCount();
        fetchAllSalePurchaseCount()
    }, []);

    const onClick = (redirect, permission) => {
        if(config && config.filter((item) => item === permission).length !== 0){
            navigate(`/${redirect}`)
        }
    }

    const [isAllsale, isSetAllsale] = useState(false);
    

    return (
        <Row className='g-4'>
            <Col className='col-12 mb-4'>
                <div className='col-lg-12 d-flex justify-content-between align-items-center'>
                    <h3>Sales & Purchases</h3>
                    <div>
                    <NavDropdown className='hide-arrow mx-2' title={<FontAwesomeIcon icon={faBars}/>} >
                                <NavDropdown.Item href='#/'
                                                  className={`${isAllsale === true ? '' : 'text-primary'} fs-6`}
                                                  onClick={() => isSetAllsale(false)}>{getFormattedMessage('All')}</NavDropdown.Item>
                                <NavDropdown.Item href='#'
                                                  className={`${isAllsale === true ? 'text-primary' : ''} fs-6`}
                                                  onClick={() => isSetAllsale(true)}>{getFormattedMessage('Today')}</NavDropdown.Item>
                            </NavDropdown>
                    </div>
                </div>
                <Row>
                    {isAllsale ? 
                    <>
                        <Widget title={getFormattedMessage("Sales Today")} allConfigData={allConfigData}
                            onClick={() => onClick('app/sales', "manage_sale")}
                            className='bg-white  cursor-pointer' iconClass='bg-gray-300'
                            icon={<FontAwesomeIcon icon={faDollar} className='fs-1-xl text-gray'/>}
                            currency={frontSetting.value && frontSetting.value.currency_symbol}
                            value={todayCount.today_sales ? parseFloat(todayCount.today_sales).toFixed(2) : '0.00'}
                            textcolor="#3B71CA"
                            />

                    <Widget title={getFormattedMessage("Return Today")} allConfigData={allConfigData}
                            onClick={() => onClick('app/purchase-return', 'manage_purchase_return')}
                            className={`bg-white ${config && config.filter((item) => item === "manage_purchase_return").length !== 0 ? "cursor-pointer" : ""}`} iconClass='bg-gray-300'
                            icon={<FontAwesomeIcon icon={faArrowLeft} className='fs-1-xl text-gray'/>}
                            currency={frontSetting.value && frontSetting.value.currency_symbol}
                            value={allSalePurchase.all_purchase_return_count ? parseFloat(allSalePurchase.all_purchase_return_count).toFixed(2) : '0.00'}
                            textcolor="#FF0000"
                            />

                    <Widget title={getFormattedMessage("Purchases Today")} allConfigData={allConfigData}
                            onClick={() => onClick('app/purchases', "manage_purchase")}
                            className='bg-white cursor-pointer' iconClass='bg-gray-300'
                            icon={<FontAwesomeIcon icon={faCartPlus} className='fs-1-xl text-gray'/>}
                            currency={frontSetting.value && frontSetting.value.currency_symbol}
                            value={todayCount.today_purchases ? parseFloat(todayCount.today_purchases).toFixed(2) : '0.00'}
                            textcolor="#14A44D"
                            />

                    <Widget title={getFormattedMessage("Expense Today")} allConfigData={allConfigData}
                            onClick={() => onClick('app/expenses', "manage_expenses")}
                            className='bg-white cursor-pointer' iconClass='bg-gray-300'
                            icon={<FontAwesomeIcon icon={faSquareMinus} className='fs-1-xl text-gray'/>}
                            currency={frontSetting.value && frontSetting.value.currency_symbol}
                            value={todayCount.today_expense_count ? parseFloat(todayCount.today_expense_count).toFixed(2) : '0.00'}
                            textcolor="#FF5733"
                            />
                    </> :
                        <>
                             <Widget title={getFormattedMessage('sales.title')}
                            onClick={() => onClick('app/sales', "manage_sale")} allConfigData={allConfigData}
                            className={`bg-white ${config && config.filter((item) => item === "manage_sale").length !== 0 ? "cursor-pointer" : ""}`} iconClass='bg-gray-300'
                            icon={<FontAwesomeIcon icon={faShoppingCart} className='fs-1-xl text-gray'/>}
                            currency={frontSetting.value && frontSetting.value.currency_symbol}
                            value={allSalePurchase.all_sales_count ? parseFloat(allSalePurchase.all_sales_count).toFixed(2) : '0.00'}
                            textcolor="#3B71CA"
                            />

                    <Widget title={getFormattedMessage('purchases.title')} allConfigData={allConfigData}
                            onClick={() => onClick('app/purchases', "manage_purchase")}
                            className={`bg-white ${config && config.filter((item) => item === "manage_purchase").length !== 0 ? "cursor-pointer" : ""}`} iconClass='bg-gray-300'
                            icon={<FontAwesomeIcon icon={faCartPlus} className='fs-1-xl text-gray'/>}
                            currency={frontSetting.value && frontSetting.value.currency_symbol}
                            value={allSalePurchase.all_purchases_count ? parseFloat(allSalePurchase.all_purchases_count).toFixed(2) : '0.00'}
                            textcolor="#14A44D"
                            />

                    <Widget title={getFormattedMessage("sales-return.title")} allConfigData={allConfigData}
                            onClick={() => onClick('app/sale-return', "manage_sale_return")}
                            className={`bg-white ${config && config.filter((item) => item === "manage_sale_return").length !== 0 ? "cursor-pointer" : ""}`} iconClass='bg-gray-300'
                            icon={<FontAwesomeIcon icon={faArrowRight} className='fs-1-xl text-gray'/>}
                            currency={frontSetting.value && frontSetting.value.currency_symbol}
                            value={allSalePurchase.all_sale_return_count ? parseFloat(allSalePurchase.all_sale_return_count).toFixed(2) : '0.00'}
                            textcolor="#FF0000"
                            />

                    <Widget title={getFormattedMessage("purchases.return.title")} allConfigData={allConfigData}
                            onClick={() => onClick('app/purchase-return', 'manage_purchase_return')}
                            className={`bg-white ${config && config.filter((item) => item === "manage_purchase_return").length !== 0 ? "cursor-pointer" : ""}`} iconClass='bg-gray-300'
                            icon={<FontAwesomeIcon icon={faArrowLeft} className='fs-1-xl text-gray'/>}
                            currency={frontSetting.value && frontSetting.value.currency_symbol}
                            value={allSalePurchase.all_purchase_return_count ? parseFloat(allSalePurchase.all_purchase_return_count).toFixed(2) : '0.00'}
                            textcolor="#FF5733"
                            />
                        
                        </>
                    }
                    

                    
                </Row>
            </Col>
        </Row>
    )
};
const mapStateToProps = (state) => {
    const {todayCount,allSalePurchase, config, allConfigData} = state;
    return {todayCount,allSalePurchase, config, allConfigData}
};

export default connect(mapStateToProps, {todaySalePurchaseCount, fetchAllSalePurchaseCount})(TodaySalePurchaseCount);
