import {setLoading} from './loadingAction';
import {apiBaseURL, stockReportActionType} from '../../constants';
import apiConfig from '../../config/apiConfig';
import {setTotalRecord} from './totalRecordAction';

export const stockDetailsWarehouseAction = (id, filter = {}, isLoading = true) => async (dispatch) => {
    if (isLoading) {
        dispatch(setLoading(true))
    }
    let url = apiBaseURL.STOCK_DETAILS_WAREHOUSE + '?product_id=' + id;
    await apiConfig.get(url)
        .then((response) => {
            dispatch({type: stockReportActionType.STOCK_DETAILS_WAREHOUSE, payload: response.data.data});
            dispatch(setTotalRecord(response.data.meta.total));
            if (isLoading) {
                dispatch(setLoading(false))
            }
        })
        .catch(({response}) => {
        });
};
