import apiConfig from '../../config/apiConfig';
import {apiBaseURL, Filters, productActionType, toastType, Tokens} from '../../constants';
import {addToast} from './toastAction'
import {setTotalRecord, addInToTotalRecord, removeFromTotalRecord} from './totalRecordAction';
import {addProductBatches,fetchProductBatches} from '../../store/action/productBatchAction';
import requestParam from '../../shared/requestParam';
import {setLoading} from './loadingAction';
import {getFormattedMessage} from '../../shared/sharedMethod';
import {setSavingButton} from "./saveButtonAction";
import { callImportProductApi } from './importProductApiAction';
import axios from 'axios';
import { environment } from '../../config/environment';




export const fetchProducts = (filter = {}, isLoading = true) => async (dispatch) => {
    if (isLoading) {
        dispatch(setLoading(true))
    }
    let url = apiBaseURL.PRODUCTS;
    if (!_.isEmpty(filter) && (filter.page || filter.pageSize || filter.search || filter.order_By || filter.created_at)) {
        url += requestParam(filter);
    }
    apiConfig.get(url)
        .then((response) => {
            dispatch({type: productActionType.FETCH_PRODUCTS, payload: response.data.data});
            dispatch(setTotalRecord(response.data.meta.total));
            if (isLoading) {
                dispatch(setLoading(false))
            }
        })
        .catch(({response}) => {
            dispatch(addToast(
                {text: response.data.message, type: toastType.ERROR}));
        });
};

export const fetchProduct = (productId, singleProduct, isLoading = true) => async (dispatch) => {
    // console.log("fetch product",productId)
    if (isLoading) {
        dispatch(setLoading(true))
    }
    apiConfig.get(apiBaseURL.PRODUCTS + '/' + productId, singleProduct)
        .then((response) => {
            console.log("product id response",response.data.data)
            dispatch({type: productActionType.FETCH_PRODUCT, payload: response.data.data});
            dispatch(fetchProductBatches(productId));
            if (isLoading) {
                dispatch(setLoading(false))
            }
        })
        .catch(({response}) => {
            // dispatch(addToast(
            //     {text: response.data.message, type: toastType.ERROR}));
        });
};

 const prepareFormData=(data)=>{
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('code', data.code);
    formData.append('product_category_id', data.product_category_id.value );
    formData.append('brand_id', data.brand_id.value);
   

    formData.append('barcode_symbol', 'default_value');
    
    formData.append('product_cost', data.product_cost);
    formData.append('product_price', data.product_price );
    formData.append('product_unit', data.product_unit && data.product_unit[0] ? data.product_unit[0].value : data.product_unit.value );
    formData.append('sale_unit', data.sale_unit && data.sale_unit[0] ? data.sale_unit[0].value : data.sale_unit.value );
    formData.append('purchase_unit', data.purchase_unit && data.purchase_unit[0] ? data.purchase_unit[0].value : data.purchase_unit.value ? data.purchase_unit.value : null);
    formData.append('stock_alert', data.stock_alert ? data.stock_alert : null);
    formData.append('order_tax', data.order_tax ? data.order_tax : null);
    formData.append('quantity_limit', data.sale_quantity_limit ? data.sale_quantity_limit : null);
    formData.append('tax_type', data.tax_type);
    formData.append('notes', data.notes );
    formData.append('product_type', 1);
    formData.append('MRP', data.MRP );
    formData.append('HSN_code', data.HSN_code);

    formData.append('purchase_supplier_id', data.supplier_id);
    formData.append('purchase_warehouse_id', data.warehouse_id);
    formData.append('purchase_date', data.purchase_date);
    formData.append('purchase_quantity', data.purchase_quantity );
    formData.append('purchase_status', data.purchase_status);
    if (multipleFiles) {
    multipleFiles.forEach((image, index) => {
    formData.append(`images[${index}]`, image);
     })
    }
    
    return formData;

}
export const addProduct = (product, productBatchesData,navigate) => async (dispatch) => {

console.log('product in add product',product)
console.log('productBatchesData in product',productBatchesData)


 let subData=   {
        name:product.name?product.name:'',
        product_type:product.product_type?product.product_type.value:'1',
        code:product.code?product.code:'',
        product_cost:product.product_cost?product.product_cost:'0',
        HSN_code:product.HSN_code?product.HSN_code:'',
        product_category_id:product.product_category_id?product.product_category_id.value:null,
        brand_id:product.brand_id?product.brand_id.value:null,
        barcode_symbol:1,
        product_price:product.product_price?product.product_price:'0',
        product_unit:product.product_unit?product.product_unit.value:'0',
        sale_unit:product.sale_unit?product.sale_unit:'0',
        purchase_unit:product.purchase_unit?product.purchase_unit:'0',
        stock_alert:product.stock_alert?product.stock_alert:'0',
        quantity_limit:product.quantity_limit?product.quantity_limit:'0',
        order_tax:product.order_tax?product.order_tax:'0',
        tax_type:product.tax_type?product.tax_type:0,
        MRP:product.MRP?product.MRP:0,
        purchase_warehouse_id:product.purchase_warehouse_id?product.purchase_warehouse_id.value:null,
        purchase_supplier_id:product.purchase_supplier_id?product.purchase_supplier_id.value:null,
        purchase_quantity:product.purchase_quantity?product.purchase_quantity:0,
        purchase_status:product.purchase_status?product.purchase_status:0,
        purchase_date:product.purchase_date?product.purchase_date:0,
        supplier_id:product.supplier_id?product.supplier_id.value:null,
        product_category_name:product.product_category_name?product.product_category_name:null,
        brand_name:product.brand_name?product.brand_name:null
    }
    
     console.log('productBatchesData for add product',productBatchesData)
     dispatch(setSavingButton(true));
  
    const token = localStorage.getItem('auth_token');
    console.log('auth_token',token)

    var config = {
        method: 'post',
        url: environment.URL+"/api/products",
        headers: { 
          'Authorization': `Bearer ${token}`, 
         
        },
        data : product,
      };
      axios(config).then(async(response)=>{
      console.log('create product response',response)
      if(response.data){
        await  dispatch({ type: productActionType.ADD_PRODUCT, payload: response.data.data });
       }
      
       await  dispatch(addToast({ text: getFormattedMessage('product.success.create.message') }));
       console.log("batch data",productBatchesData.batch_no)
     

       
        const productId = response.data.data.id;

        productBatchesData.product_id =productId
        if(productBatchesData.batch_no){
            await dispatch(addProductBatches(productBatchesData,navigate))
        }
        navigate('/app/products');
           
         
       
        dispatch(addInToTotalRecord(1));
        dispatch(setSavingButton(false));

    }) .catch(({response}) => {
     console.log('create product error res',response)
      dispatch(setSavingButton(false));
      dispatch(addToast({ text: response?.data?.message, type: toastType.ERROR }));
    });
    
  };

export const editProduct = (productId, product, navigate) => async (dispatch) => {
    dispatch(setSavingButton(true))
    apiConfig.post(apiBaseURL.PRODUCTS + '/' + productId, product)
        .then((response) => {
          //  navigate('/app/products')
            dispatch(addToast({text: getFormattedMessage('product.success.edit.message')}));
            dispatch({type: productActionType.EDIT_PRODUCT, payload: response.data.data});
            dispatch(setSavingButton(false))
        })
        .catch(({response}) => {
            dispatch(setSavingButton(false))
            dispatch(addToast(
                {text: response.data.message, type: toastType.ERROR}));
        });
};

export const deleteProduct = (productId) => async (dispatch) => {
    apiConfig.delete(apiBaseURL.PRODUCTS + '/' + productId)
        .then((response) => {
            dispatch(removeFromTotalRecord(1));
            dispatch({type: productActionType.DELETE_PRODUCT, payload: productId});
            dispatch(addToast({text: getFormattedMessage('product.success.delete.message')}));
        })
        .catch(({response}) => {
            dispatch(addToast(
                {text: response.data.message, type: toastType.ERROR}));
        });
};

export const fetchAllProducts = () => async (dispatch) => {
    apiConfig.get(`products?page[size]=0`)
        .then((response) => {
            dispatch({type: productActionType.FETCH_ALL_PRODUCTS, payload: response.data.data});
        })
        .catch(({response}) => {
            dispatch(addToast(
                {text: response.data.message, type: toastType.ERROR}));
        });
};

export const fetchProductsByWarehouse = (id) => async (dispatch) => {
    apiConfig.get(`products?page[size]=0&warehouse_id=${id}`)
        .then((response) => {
            dispatch({type: productActionType.FETCH_PRODUCTS_BY_WAREHOUSE, payload: response.data.data});
        })
        .catch(({response}) => {
            dispatch(addToast(
                {text: response.data.message, type: toastType.ERROR}));
        });
};

export const addImportProduct= (importProduct) => async (dispatch) => {
    await apiConfig.post(apiBaseURL.IMPORT_PRODUCT, importProduct)
        .then((response) => {
            dispatch(setLoading(false))
            dispatch(callImportProductApi(true))
            // dispatch({type: productActionType.ADD_IMPORT_PRODUCT, payload: response.data.data});
            dispatch(addToast({text: 'Product Import Create Success '}));
            dispatch(addInToTotalRecord(1))
        })
        .catch(({response}) => {
            dispatch(addToast(
                {text: response.data.message, type: toastType.ERROR}));
        });
};