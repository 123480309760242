import {saleActionType} from '../../constants';

export default (state = {}, action) => {
    console.log('stateFetch',state);
    switch (action.type) {
        case saleActionType.FETCH_CREDIT_NOTE:
            return action.payload;
            case saleActionType.FETCH_SINGLE_CREDIT:
                return action.payload;
        case saleActionType.ADD_CREDIT_NOTE:
            return action.payload;
        case saleActionType.EDIT_CREDIT_NOTE:
            return state.map(item => item.id === +action.payload.id ? action.payload : item);
         case saleActionType.DELETE_CREDIT_NOTE:
             return state.filter(item => item.id !== action.payload);
        default:
            return state;
    }
};
