import React, {useEffect, useState} from 'react';
import {Card, Badge} from 'react-bootstrap-v5';
import {connect, useDispatch} from 'react-redux';
import useSound from 'use-sound';
import {posFetchProduct} from '../../../store/action/pos/posfetchProductAction';
import {posAllProduct} from '../../../store/action/pos/posAllProductAction';
import productImage from '../../../assets/images/brand_logo.png';
import {addToast} from "../../../store/action/toastAction";
import {currencySymbolHendling, getFormattedMessage} from "../../../shared/sharedMethod";
import {toastType} from "../../../constants";

const Product = (props) => {
    const {
        posAllProduct,
        posAllProducts,
        posFetchProduct,
        cartProducts,
        updateCart,
        customCart,
        cartProductIds,
        setCartProductIds,
        settings,
        productMsg,
        newCost, selectedOption, allConfigData
    } = props;
    const [updateProducts, setUpdateProducts] = useState([]);
    const [play] = useSound('https://s3.amazonaws.com/freecodecamp/drums/Heater-4_1.mp3');
    const dispatch = useDispatch();
    // useEffect(() => {
    //     if (selectedOption) {
    //         posAllProduct(selectedOption && selectedOption.value);
    //     }
    // }, [selectedOption]);

    useEffect(() => {
        // update cart while cart is updated
        cartProducts && setUpdateProducts(cartProducts);
        const ids = updateProducts.map(item => {
            return item.id
        })
        setCartProductIds(ids);
    }, [updateProducts, cartProducts]);

    const addToCart = (product) => {
        play();
        posFetchProduct(product.id);
        addProductToCart(product);
    };

    const addProductToCart = (product) => {
        const newId = posAllProducts.filter((item) => item.id === product.id).map((item) => item.id)
        const finalIdArrays = customCart.map((id) => id.product_id);
        const finalId = finalIdArrays.filter((finalIdArray) => finalIdArray === newId[0]);
        const pushArray = [...customCart];
        const newProduct = pushArray.find(element => element.id === finalId[0]);
        const filterQty = updateProducts.filter(item => item.id === product.id).map((qty) => qty.quantity)[0]
        if (updateProducts.filter(item => item.id === product.id).length > 0) {
            if(filterQty >= product.attributes.stock.quantity) {
                dispatch(addToast({
                    text: getFormattedMessage('Quantity exceeds quantity available in stock'),
                    type: toastType.ERROR
                }));
            } else {
                setUpdateProducts(updateProducts => updateProducts.map(item => item.id === product.id
                    ? {...item, quantity: product.attributes.stock.quantity > item.quantity ? item.quantity++ + 1 : null}
                    : {...item, id: item.id}
                ));
                updateCart(updateProducts, product)
            }
        } else {
            setUpdateProducts(prevSelected => [...prevSelected, product])
            updateCart(prevSelected => [...prevSelected, newProduct]);
        }
    };

    const isProductExistInCart = (productId) => {
        return cartProductIds.includes(productId);
    };

    const posFilterProduct = posAllProducts && posAllProducts.filter((product)=> product.attributes.stock.quantity > 0.00)
    //Cart Item Array
    const loadAllProduct = (product, index) => {
        return (
            product.attributes.stock.quantity >= !0.00 ?
                <div className='product-custom-card' key={index} onClick={() => addToCart(product)}>
                    <Card
                        className={`position-relative h-100 ${isProductExistInCart(product.id) ? 'product-active' : ''}`}>
                        <Card.Img variant='top'
                                  src={product.attributes.images.imageUrls ? product.attributes.images.imageUrls[0] : productImage}/>
                        <Card.Body className='px-2 pt-2 pb-1 custom-card-body'>
                            <h6 className='product-title mb-0 text-gray-900'>{product.attributes.name}</h6>
                            <span className='fs-small text-gray-700'>{product.attributes.code}</span>
                           <p className='m-0 item-badges'>
                                <Badge bg='info' text='white' className='product-custom-card__card-badge'>
                                    {product.attributes.stock && product.attributes.stock.quantity} {product?.attributes?.product_unit_name?.name}
                                </Badge>
                            </p>
                            <p className='m-0 item-badge'>
                                <Badge bg='primary' text='white' className='product-custom-card__card-badge'>
                                    {currencySymbolHendling(allConfigData, settings.attributes && settings.attributes.currency_symbol, newCost ? newCost : product.attributes.product_price)}
                                </Badge>
                            </p>
                        </Card.Body>
                    </Card>
                </div> : ''

        )
    };

    return (
        <div className={`${posFilterProduct && posFilterProduct.length === 0 ? 'd-flex align-items-center justify-content-center' : ''} product-list-block pt-1`}>
            <div className='d-flex flex-wrap product-list-block__product-block'>
                {posFilterProduct && posFilterProduct.length === 0 ? <h4 className='m-auto'>{getFormattedMessage("pos-no-product-available.label")}</h4> : ''}
                {productMsg && productMsg === 1 ?
                    <h4 className='m-auto'>
                        {getFormattedMessage("pos-no-product-available.label")}
                    </h4>
                    :
                    posFilterProduct && posFilterProduct.map((product, index) => {
                    return loadAllProduct(product, index)
                })}
            </div>
        </div>
    )
};

const mapStateToProps = (state) => {
    const {posAllProducts, allConfigData} = state;
    return {posAllProducts, allConfigData}
};

export default connect(mapStateToProps, {posAllProduct, posFetchProduct})(Product);