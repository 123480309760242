import {productBatchActionType} from '../../constants';

export default (state = [], action) => {
    console.log("actions", action.payload)
    switch (action.type) {

        case productBatchActionType.FETCH_PRODUCT_BATCHES:
            return action.payload;
         case productBatchActionType.ADD_PRODUCT_BATCH:
                return action.payload;
        case productBatchActionType.EDIT_PRODUCT_BATCH:
            return state.map(item => item.id === +action.payload.id ? action.payload : item);

        default:
            return state;
    }
};
