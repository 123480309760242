import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import CreditNoteForm from './CreditNoteForm';
import MasterLayout from '../MasterLayout';
import HeaderTitle from '../header/HeaderTitle';
import { fetchCreditNote ,} from '../../store/action/salesCreditNote';
import { fetchCredit } from '../../store/action/salesCreditNote';
import { editCreditNote } from '../../store/action/salesCreditNote';
import {fetchAllCustomer} from '../../store/action/customerAction';
import {fetchAllWarehouses} from '../../store/action/warehouseAction';
import {getFormattedMessage,getFormattedOptions} from '../../shared/sharedMethod';
import status from '../../shared/option-lists/status.json';
import paymentStatus from '../../shared/option-lists/paymentStatus.json';
import paymentType from '../../shared/option-lists/paymentType.json';
import Spinner from "../../shared/components/loaders/Spinner";
import TopProgressBar from "../../shared/components/loaders/TopProgressBar";
import { saleStatusOptions } from '../../constants';

const EditCreditNote = (props) => {
    const {
          fetchCredit, 
          customers, 
          fetchAllCustomer, 
          warehouses,
          fetchAllWarehouses, 
          isLoading,
          creditNote
        } = props;   
  
    const {id} = useParams();
    console.log("editId",id)


    useEffect(() => {
        fetchAllCustomer();
        fetchAllWarehouses();
        fetchCredit(id);
    }, []);
    

    const statusFilterOptions = getFormattedOptions(saleStatusOptions)
    const statusDefaultValue =  creditNote.attributes && creditNote.attributes.status && statusFilterOptions.filter((option) => option.id === creditNote.attributes.status)
    const selectedPayment = creditNote.attributes && creditNote.attributes.payment_status && paymentStatus.filter((item) => item.value === creditNote.attributes.payment_status)
    const selectedPaymentType = creditNote.attributes && creditNote.attributes.payment_type && paymentType.filter((item) => item.value === creditNote.attributes.payment_type)

    const itemsValue = creditNote && creditNote.attributes && {
        date: creditNote.attributes.date,
        warehouse_id: {
            value: creditNote.attributes.warehouse_id,
            label: creditNote.attributes.warehouse_name,
        },
        customer_id: {
            value: creditNote.attributes.customer_id,
            label: creditNote.attributes.customer_name,
        },
        tax_rate: creditNote.attributes.tax_rate,
        tax_amount: creditNote.attributes.tax_amount,
        discount: creditNote.attributes.discount,
        amount_received: creditNote.attributes.amount_received,
        shipping: creditNote.attributes.shipping,
        grand_total: creditNote.attributes.grand_total,
        amount: creditNote.attributes.amount,
        credit_note_items:creditNote.attributes.credit_note_items&& creditNote.attributes.credit_note_items.map((item) => ({
            code: item.product && item.product.code,
            name: item.product && item.product.name,
           // product_unit: item.product.product_unit,
            product_id: item.product_id,
            short_name: item.sale_unit && item.sale_unit.short_name && item.sale_unit.short_name,
            stock_alert:  item.product && item.product.stock_alert,
            product_price: item.product_price,
            fix_net_unit: item.product_price,
            net_unit_price: item.product_price,
            tax_type: item.tax_type,
            tax_value: item.tax_value,
            tax_amount: item.tax_amount,
            discount_type: item.discount_type,
            discount_value: item.discount_value,
            discount_amount: item.discount_amount,
            isEdit: true,
            stock: item.product && item.product.stocks.filter(item => item.warehouse_id === creditNote.attributes.warehouse_id),
            sub_total: item.sub_total,
            sale_unit: item.sale_unit && item.sale_unit.id && item.sale_unit.id,
            quantity: item.quantity,
            id: item.id,
            credit_id: item.id,
            newItem: '',
        })),
        id: creditNote.id,
        notes: creditNote.attributes.note,
        is_Partial : creditNote.attributes.payment_status,
        payment_status: {
            label: selectedPayment && selectedPayment[0] && selectedPayment[0].label,
            value: selectedPayment && selectedPayment[0] && selectedPayment[0].value
        },
        payment_type: {
            label: selectedPaymentType && selectedPaymentType[0] && selectedPaymentType[0].label,
            value: selectedPaymentType && selectedPaymentType[0] && selectedPaymentType[0].value
        },
        status_id: {
            label: statusDefaultValue && statusDefaultValue[0] && statusDefaultValue[0].name,
            value: statusDefaultValue && statusDefaultValue[0] && statusDefaultValue[0].id
        }
    };

   console.log("itesvalues",itemsValue);



    return (
        <MasterLayout>
            <TopProgressBar/>
            <HeaderTitle title={getFormattedMessage('Edit Credit Note')} to='/app/credit-note'/>
            {isLoading ? <Spinner /> :
          //  <h1>ello</h1>
          itemsValue&&  <CreditNoteForm singleSale={itemsValue} id={id} customers={customers} warehouses={warehouses}/>
        }
        </MasterLayout>
        
    )
    
};

const mapStateToProps = (state) => {
    console.log('mapStateToProps',state)
    const {customers, warehouses, isLoading,creditNote} = state;
    return {creditNote, customers, warehouses, isLoading,creditNote}
};

export default connect(mapStateToProps, {fetchCredit,editCreditNote,fetchCreditNote, fetchAllCustomer, fetchAllWarehouses})(EditCreditNote);
