import apiConfig from '../../config/apiConfig';
import {apiBaseURL, saleReturnActionType, toastType} from '../../constants';
import {addToast} from './toastAction';
import {addInToTotalRecord, removeFromTotalRecord, setTotalRecord} from './totalRecordAction';
import {setLoading} from './loadingAction';
import requestParam from '../../shared/requestParam';
import {getFormattedMessage} from '../../shared/sharedMethod';
import {setSavingButton} from "./saveButtonAction";

export const fetchSalesReturn = (filter = {}, isLoading = true) => async (dispatch) => {
    if (isLoading) {
        dispatch(setLoading(true))
    }
    const admin = true;
    let url = apiBaseURL.SALE_RETURN;
    if (!_.isEmpty(filter) && (filter.page || filter.pageSize || filter.search || filter.order_By || filter.created_at)) {
        url += requestParam(filter, admin);
    }
    await apiConfig.get(url)
        .then((response) => {
            dispatch({type: saleReturnActionType.FETCH_SALES_RETURN, payload: response.data.data});
            dispatch(setTotalRecord(response.data.meta.total));
            if (isLoading) {
                dispatch(setLoading(false))
            }
        })
        .catch(({response}) => {
            dispatch(addToast(
                {text: response.data.message, type: toastType.ERROR}));
        });
};

export const fetchSaleReturn = (saleId, isSaleReturnFromSale, isLoading = true) => async (dispatch) => {
    if (isLoading) {
        dispatch(setLoading(true))
    }
    await apiConfig.get(!isSaleReturnFromSale ? apiBaseURL.SALE_RETURN + '/' + saleId + '/edit' : apiBaseURL.EDIT_SALE_FROM_SALE + "/" + saleId)
        .then((response) => {
            dispatch({type: saleReturnActionType.FETCH_SALE_RETURN, payload: response.data.data})
            if (isLoading) {
                dispatch(setLoading(false));
            }
        })
        .catch(({response}) => {
            dispatch(addToast(
                {text: response.data.message, type: toastType.ERROR}));
        });
};

export const addSaleReturn = (sale, navigate) => async (dispatch) => {
    dispatch(setSavingButton(true))
    await apiConfig.post(apiBaseURL.SALE_RETURN, sale)
        .then((response) => {
            dispatch({type: saleReturnActionType.ADD_SALE_RETURN, payload: response.data.data});
            dispatch(addToast({text: getFormattedMessage('sale-return.success.create.message')}));
            dispatch(addInToTotalRecord(1));
            navigate('/app/sale-return');
            dispatch(setSavingButton(false))
        })
        .catch(({response}) => {
            dispatch(setSavingButton(false))
            dispatch(addToast(
                {text: response.data.message, type: toastType.ERROR}));
        });
};

export const editSaleReturn = (saleId, sale, navigate) => async (dispatch) => {
    dispatch(setSavingButton(true))
    await apiConfig.patch(apiBaseURL.SALE_RETURN + '/' + saleId, sale)
        .then((response) => {
            dispatch(addToast({text: getFormattedMessage('sale-return.success.edit.message')}));
            navigate('/app/sale-return');
            dispatch({type: saleReturnActionType.EDIT_SALE_RETURN, payload: response.data.data});
            dispatch(setSavingButton(false))
        })
        .catch(({ response }) => {
            dispatch(setSavingButton(false))
            dispatch(addToast(
                {text: response.data.message, type: toastType.ERROR}));
        });
};

export const deleteSaleReturn = (userId) => async (dispatch) => {
    await apiConfig.delete(apiBaseURL.SALE_RETURN + '/' + userId)
        .then(() => {
            dispatch(removeFromTotalRecord(1));
            dispatch({type: saleReturnActionType.DELETE_SALE_RETURN, payload: userId});
            dispatch(addToast({text: getFormattedMessage('sale-return.success.delete.message')}));
        })
        .catch(({response}) => {
            dispatch(addToast(
                {text: response.data.message, type: toastType.ERROR}));
        });
};
