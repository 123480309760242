import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import SalesForm from '../sales/SalesForm';
import MasterLayout from '../MasterLayout';
import HeaderTitle from '../header/HeaderTitle';
import {addSale} from '../../store/action/salesAction';
import {fetchAllCustomer} from '../../store/action/customerAction';
import {fetchAllWarehouses} from '../../store/action/warehouseAction';
import {getFormattedMessage} from '../../shared/sharedMethod';
import CreditNoteForm from './CreditNoteForm';
import { addCreditNote } from '../../store/action/salesCreditNote';

const CreateCreditNote = (props) => {
    const {addCreditNote, customers, fetchAllCustomer, warehouses, fetchAllWarehouses} = props;
    const navigate = useNavigate();

    useEffect(() => {
        fetchAllCustomer();
        fetchAllWarehouses();
    }, []);

    const addCreditNoteData = (formValue) => {
        console.log('formValue',formValue);
        addCreditNote(formValue, navigate);
    };

    return (
        <MasterLayout>
            <HeaderTitle title={getFormattedMessage('Credit Note')} to='/app/credit-note'/>
            <CreditNoteForm addCreditNoteData={addCreditNoteData} customers={customers} warehouses={warehouses}/>
        </MasterLayout>
    )
};

const mapStateToProps = (state) => {
    const {customers, warehouses, totalRecord} = state;
    return {customers, warehouses, totalRecord}
};

export default connect(mapStateToProps, {addCreditNote, fetchAllCustomer, fetchAllWarehouses})(CreateCreditNote);
