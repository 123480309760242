import apiConfig from '../../config/apiConfig';
import {apiBaseURL, saleActionType, toastType} from '../../constants';
import {addToast} from './toastAction';
import {addInToTotalRecord, removeFromTotalRecord, setTotalRecord} from './totalRecordAction';
import {setLoading} from './loadingAction';
import requestParam from '../../shared/requestParam';
import {getFormattedMessage} from '../../shared/sharedMethod';
import {callSaleApi} from "./saleApiAction";
import {setSavingButton} from "./saveButtonAction";




export const fetchCreditNote = (filter = {}, isLoading = true) => async (dispatch) => {
        if (isLoading) {
            dispatch(setLoading(true))
        }
        const admin = true;
        let url = apiBaseURL.CREDIT_NOTE
        console.log('url',url);
        if (!_.isEmpty(filter) && (filter.page || filter.pageSize )) {
            url += requestParam(filter, admin);
        }
        await apiConfig.get(url)
            .then((response) => {
                console.log('responseFetch',response);
                dispatch({type: saleActionType.FETCH_CREDIT_NOTE, payload: response.data.data});
                dispatch(setTotalRecord(response.data.meta.total));
                dispatch(callSaleApi(false))
                if (isLoading) {
                    dispatch(setLoading(false))
                }
            })
            .catch(({response}) => {
                dispatch(addToast(
                    {text: response.data.message, type: toastType.ERROR}));
            });
    
    }

   

export const addCreditNote = (creditNote, navigate) => async (dispatch) => {
    console.log('creditNotess',creditNote);
    console.log('navigate',navigate);

   dispatch(setSavingButton(true))
    await apiConfig.post(apiBaseURL.CREDIT_NOTE, creditNote)
        .then((response) => {
            console.log('responseADDCredit',response);
            dispatch({type: saleActionType.ADD_CREDIT_NOTE, payload: response.data.data});
            dispatch(addToast({text: getFormattedMessage('credit.note.created.succesfully')}));
            dispatch(addInToTotalRecord(1));
            navigate('/app/credit-note');
            dispatch(setSavingButton(false))
        })
        .catch(({response}) => {
            // dispatch(setSavingButton(false))
            // dispatch(addToast(
            //     {text: response.data.message, type: toastType.ERROR}));
        });
};
export const fetchCredit = (creditId, singleSale, isLoading = true) => async (dispatch) => {
    if (isLoading) {
        dispatch(setLoading(true))
    }  
  
    await apiConfig.get(apiBaseURL.CREDIT_NOTE + '/' + creditId + singleSale)
        .then((response) => {
            console.log("fetched",response)
            dispatch({type: saleActionType.FETCH_SINGLE_CREDIT, payload: response.data.data})
            if (isLoading) {
                dispatch(setLoading(false));
            }
        })
        .catch(({response}) => {
            dispatch(addToast(
                {text: response.data.message, type: toastType.ERROR}));
        });
};

 export const editCreditNote = (creditId, creditNote, navigate) => async (dispatch) => {
    console.log("navigate ",creditNote)
     dispatch(setSavingButton(true))
     await apiConfig.put(apiBaseURL.CREDIT_NOTE + '/' + creditId, creditNote)
         .then((response) => {
            console.log("resp",response);
            navigate('/app/credit-note');
             dispatch(addToast({text: getFormattedMessage('credit.note.update.succesfully')}));
             dispatch({type: saleActionType. EDIT_CREDIT_NOTE, payload: response.data.data});
             
             dispatch(setSavingButton(false))
         })
         .catch(({ response }) => {
            console.log("apiCall Failed");
             dispatch(setSavingButton(false))
             dispatch(addToast(
                 {text: response.data.message, type: toastType.ERROR}));
         });
 };

 export const deleteCreditNote = (userId) => async (dispatch) => {
     await apiConfig.delete(apiBaseURL.CREDIT_NOTE + '/' + userId)
         .then(() => {
             dispatch(removeFromTotalRecord(1));
             dispatch({type: saleActionType.DELETE_CREDIT_NOTE, payload: userId});
             dispatch(addToast({text: getFormattedMessage('credit.note.deleted.succesfully')}));
         })
        .catch(({response}) => {
            response && dispatch(addToast(
                {text: response.data.message, type: toastType.ERROR}));
         });
 };
